import styled from "styled-components";
import { useEffect } from "react";
import { Containers, Hero, Phead } from "./style";
import { ProductCard } from "../CardContainer";
import logo from "../../../image/brandLogo.png";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../component/Navbar";
import SupportWidget from "../../../component/SupportWidget";
import Footer from "../../../component/Footer";
import Fade from "react-reveal/Fade";
import mockimg from "../../../image/cobankheroimg.png";
import cobiz from "../../../image/cobiz1.png";
import { Helmet } from "react-helmet";

import { Button } from "../../../component/Button";

const Cooperative = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Riby Cooperative Business";
  }, []);

  return (
    <>
      <Navbar img="/Logo.png" />
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Riby Cooperative Business - Providing technology and management solutions for
          cooperatives, savings groups and associations.{" "}
        </title>
        <meta
          name="description"
          content="Riby Cobanking is winning the race to becoming Nigeria's best cooperative software
          solution. You can grow your cooperative members, carryout thrift collection activities, as
          well as managing loan disbursement and repayment. Get started"
        />
      </Helmet>
      <Containers>
        <Fade top>
          <Hero>
            <h3>Cooperative Business</h3>
            <p>
              We have years of experience providing technology and management solutions for
              coperatives, savings groups and associations.
            </p>
          </Hero>
        </Fade>
        <Phead>Cooperative society</Phead>
        <ProductCard
          ta
          fd
          fs="0.9rem"
          img={mockimg}
          textHead={
            <TextHead>
              <img src={logo} alt="logo-img" /> Riby CoBanking
            </TextHead>
          }
          textDesc={
            <TextDesc>
              Riby CoBanking is a complete financial management tool for Cooperatives, trade groups,
              associations and svings groups. Members can save and make contributions seamlessly,
              create and manage different loans efficiently, make investments together and enjoy
              access to financial services provided by Riby’s financial partners.
            </TextDesc>
          }
          btn={<Button title="Get Started" onClick={() => navigate("/registercooperative")} />}
        />
        <Phead style={{ marginTop: "1.3rem" }}>Cooperative Services</Phead>
        <ProductCard
          fs="0.9rem"
          img={cobiz}
          ta
          textHead={<TextHead>Riby Cooperative Services</TextHead>}
          textDesc={
            <>
              <TextDesc>
                Our service unit is designed to offer consulting and management services to both
                formal and informal cooperatives, trade groups and associations. Our service
                includes:
              </TextDesc>
              <ul>
                <Li>Cooperative setup and registration with the regulatory body</Li>
                <Li>Cooperative branding and website development</Li>
                <Li>Administrative management of cooperative activities</Li>
                <Li>
                  Provision of financial literacy and engagement for Informal Groups and
                  Cooperatives
                </Li>
                <Li>
                  Linking cooperatives and groups to Riby partnered financial service providers eg
                  credit and loans institution, insurance organisation, investments firms, HMOs etc
                </Li>
              </ul>
              <Button title="Get Started" onClick={() => navigate("/registercooperative")} />
            </>
          }
        />
        <SupportWidget />
        <Footer />
      </Containers>
    </>
  );
};
export default Cooperative;

const TextHead = styled.h4`
  margin: 0;
  padding: 0;
  ont-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #0f0738;
  @media (max-width: 600px) {
    font-size: 1.2rem;
    margin-top: 20px;
  }
`;
const TextDesc = styled.p`
  margin: 0;
  padding: 0;
  font-size: 1rem;
  color: #0f0738 !important;
`;

const Li = styled.li`
  margin: 0;
  padding: 0px;
  font-size: 1rem;
  color: #0f0738;
`;
