// import react, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
// import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import coBank from "../../image/RibyLogomark.svg";
import agentLogo from "../../image/agentlogomark.svg";
import ribygo from "../../image/ribygo.png";
import rico from "../../image/rico.png";
import { Container, Wrapper, TopFlex, TitleHead } from "./styles";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import coMock from "../../image/landing-cobank.png";
import goMock from "../../image/coagentsImg.png";
import agentMock from "../../image/landing-go.png";
import agentcoMock from "../../image/landingagentImg.png";

const Products = () => {
  const data = [
    {
      img: coBank,
      productTitle: "Riby CoBanking ",
      productDesc: "A digital cooperative managment platform that suits your needs",
      productLink: "/product/CobankingApp",
      available: true,
      bImg: coMock,
      bSize: "center",
      bs: "115%",
      fade: "left",
      lg: "linear-gradient(180deg, rgba(95, 16, 132, 0.7) 0%, #ce3792 100%);",
    },
    {
      img: agentLogo,
      productTitle: "Riby Agent Network ",
      productDesc: "Our agency banking mobile app for all financial services and transactions.",
      productLink: "/product/agentbank",
      available: true,
      bImg: agentMock,
      bSize: "50% 150px",
      bs: "80%",
      fade: "right",
      lg: "linear-gradient(180deg, rgba(15, 101, 149, 0.7) 11.9%, #94F1CA 100%)",
    },

    {
      img: rico,
      productTitle: "Riby CoAgent ",
      productDesc:
        "A cooperative collection and managment app for thrift and cooperative collection",

      productLink: "/product/coagent",
      available: true,
      bImg: agentcoMock,
      bSize: "50% 120px",
      bs: "80%",
      fade: "left",
      lg: "linear-gradient(180deg, rgba(72, 53, 206, 0.7) 0%, #0F0738 100%)",
    },
    {
      img: ribygo,
      productTitle: "RibyGo ",
      productDesc: "Individual or group saving mobile app suitable for your savings need",
      productLink: "",
      available: false,
      bImg: goMock,
      bSize: "center",
      bs: "115%",
      fade: "left",
      lg: "linear-gradient(180deg, rgba(43, 0, 81, 0.8) 0%, #F94144 100%)",
    },
  ];

  return (
    <Container>
      <Wrapper>
        <Fade bottom>
          <TopFlex>
            <TitleHead>Our Products</TitleHead>
          </TopFlex>
        </Fade>

        <ProductContainer>
          {data.map((data, index) => (
            <Fade bottom>
              <ProductCard bp={data.bSize} bg={data.bImg} bs={data.bs} key={index}>
                <PFade lg={data.lg}></PFade>

                {/* <Fade position={data?.fade}> */}
                <ProductContent>
                  <Logo src={data.img} />
                  <TextHead>{data.productTitle}</TextHead>
                  <Desc>{data.productDesc}</Desc>
                  {data.available ? (
                    <LinkButton to={data.productLink}>Learn More</LinkButton>
                  ) : (
                    <ButtonDiv disabled>Coming soon</ButtonDiv>
                  )}
                </ProductContent>
                {/* </Fade> */}
              </ProductCard>
            </Fade>
          ))}
        </ProductContainer>
      </Wrapper>
    </Container>
  );
};
export default Products;

const ProductContent = styled.div`
  padding: 20px;
  position: absolute;
  top: 0;
  z-index: 3;
`;
const Desc = styled.div`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
const TextHead = styled.p`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
`;
const LinkButton = styled(Link)`
  width: 144px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d80c1b;
  border-radius: 10px;
  font-size: 14px;
  color: #fff;
  border: 0;
  outline: none;
  margin-top: 20px;

  &:hover {
    color: #0f0738;
  }
`;
const Logo = styled.img`
  width: 40px;
`;

const PFade = styled.div`
  postion: absolute;
  top: 0;
  left: 0;
  background: ${({ lg }) => lg};
  opacity: 0.6;
  width: 500px;
  min-height: 70vh;
  clip: rect(10px, 20px, 30px, 40px);
  height: 100%;
  border-radius: 7px;

  @media (max-width: 768px) {
    width: 300px;
    min-height: 30vh;
    height: 100%;
    // min-height: 40vh;
  }
`;
const ProductCard = styled.div`
  width: 500px;
  display: inline-block;
  position: relative;
  min-height: 70vh;
  height: 100%;
  margin: 20px;
  position: relative;
  color: #fff;
  border-radius: 10px;
  // background-color: #0f0738;
  opacity: 0.2;

  // background: linear-gradient(180deg, rgba(15, 7, 56, 0.2) 0%, #0f0738 100%);
  background-image: url(${({ bg }) => bg});
  // background-position: ${({ bp }) => bp};
  // background-size: ${({ bs }) => bs};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.8;
  box-shadow: 0;

  @media (max-width: 768px) {
    width: 300px;
    height: 30vh;
  }
  &:hover {
    box-shadow: 0px 0px 6px 2px rgba(15, 7, 56, 0.1);
    cursor: pointer;
    transition: all 350ms;
  }
`;
const ProductContainer = styled.div`
  color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const ButtonDiv = styled.button`
width: 144px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  outline:none;
  border:0;
  font-size: 14px;
  color:#d80c1b ;
  border: 0;
  outline: none;
  margin-top: 20px;
  }
`;
