import { useEffect } from "react";
import styled from "styled-components";
import { ProductCard, VideoContainer } from "../CardContainer";
import SupportWidget from "../../../component/SupportWidget";
import Footer from "../../../component/Footer";
import { Containers, Hero, Phead } from "../CooperativeManagement/style";
import Fade from "react-reveal/Fade";
import finimg1 from "../../../image/finimg1.png";
import finimg2 from "../../../image/finimg2.png";
import finimg3 from "../../../image/finimg3.png";
import finimg4 from "../../../image/finimg4.png";
import ribyImg from "../../../image/ribyImg.jpeg";
import Navbar from "../../../component/Navbar";
import mesBox from "../../../image/mesBox.png";
import phoneImg from "../../../image/phoneImg.png";
import whatsappIcon from "../../../image/whatsappIcon.png";
import { finIncData } from "../../../helper/careerdata";
import "../../Career/Career-Section4/Career-Section4.css";
import { Underline } from "../../Product/AgentBank";
import efinaImg from "../../../image/efina.png";
import { Helmet } from "react-helmet";

const Cooperative = () => {
  useEffect(() => {
    document.title = "Be Financially Involved with Riby Finance";
  }, []);
  return (
    <>
      <Navbar img="/Logo.png" />
      <Helmet>
        <meta charSet="utf-8" />
        <title>BE FINANCIALLY INVOLVED - RIBY FININCIAL INCLUSION PROGRAM</title>
        <title>
          Riby financial inclusion program - We are called to financially include all, Riby have
          participated in a range of economic sustainable projects and programs in different
          locations in Nigeria with some globally recognized partners{" "}
        </title>
        <meta
          name="description"
          content="Riby Cobanking is winning the race to becoming Nigeria's best cooperative software
          solution. You can grow your cooperative members, carryout thrift collection activities, as
          well as managing loan disbursement and repayment. Get started"
        />
      </Helmet>
      <Containers>
        <Fade top>
          <Hero>
            <h3>Financial Inclusion Programs</h3>
            <p>
              We have participated in a range of economic sustainable projects and programs in
              different locations in Nigeria with some globally recognized partners
            </p>
          </Hero>
        </Fade>

        <ProductCard
          ta
          img={finimg1}
          fd
          textHead={<TextHead>What We Do</TextHead>}
          textDesc={
            <>
              <TextDesc>
                We partner with various organizations to drive and deepen financial inclusion
                through a sustainable and replicable approach across different groups of people and
                communities across the country. We understand that linking people to formal
                financial institution alone doesn’t guarantee a successful financial inclusion for
                such an individual or group.
              </TextDesc>
              <TextDesc>
                {" "}
                We have developed a model which incorporates financial, economic, and information
                inclusion. This we believe is a sustainable and long-lasting approach to deepen
                economic and financial development for the underbanked and unbanked sector of the
                economy.
              </TextDesc>
            </>
          }
        />
        <Phead>Projects And Programs</Phead>
        <ProductCard
          img={finimg2}
          ta
          fd
          textHead={<TextHead>Riby Cooperative Services</TextHead>}
          textDesc={
            <>
              <TextDesc>
                Business owners, traders, and artisans are tied together either by the location of
                their business or trade and business types. These are a group of people who trade
                daily within their business location. At Riby, our goal is to bring such group of
                people together and form them into a fully functional and effective Economic
                Clusters or Cooperative (if they haven’t done that themselves). This Cooperative
                becomes the bridge, bringing all the members together and providing the foundation
                for financial and economic support to its members to grow and expand their
                respective businesses either through their internal pool of savings or through Riby
                and her lending partners.
              </TextDesc>
              <TextDesc>
                {" "}
                These small business owners are not generating enough revenue hence they are unable
                to expand their business or make any savings from their business venture, this we
                have discovered to be a major challenge. This led to development of Riby Loan to
                Saving Programme (LTSP)
              </TextDesc>
            </>
          }
        />
        <ProductCard
          ta
          img={efinaImg}
          textDesc={
            <>
              <TextDesc>
                The Riby Loan to saving program is aimed at providing affordable micro loans to
                Cooperative members who saves simultaneously as loan repayment is in progress. It is
                expected the member would have saved approximately 20% of the loan amount by the end
                of loan repayment. The loan amount increases with good loan performance per cycle.
              </TextDesc>
              <TextDesc>
                Our Loan to Savings Program for communities, started at Makoko Community situated
                along the popular Third Mainland Bridge in Lagos Nigeria, the community is inhabited
                by fishermen, lumber factories, traders, and wood housing built on top of the Lagos
                Lagoon Coastline with 85,000 people composed mostly of women.
              </TextDesc>
              <TextDesc>
                {" "}
                The programme expanded to other areas within the state such as Ajegunle, Ikotun,
                Okokomaiko and to some parts of Ogun State. The programme reached over 2,000 small
                business owners and traders across the various location
              </TextDesc>
            </>
          }
        />
        <ProductCard
          ta
          fd
          img={finimg3}
          textDesc={
            <>
              <TextDesc>
                In March 2019, Riby partnered with Enhancing Financial Innovation and Access (EFInA)
                to further promote financial inclusion in some selected states with the highest
                number of financially excluded people across the country.
              </TextDesc>
              <TextDesc>
                10 States were selected across the country which includes: Adamawa, Akwa-Ibom,
                Bauchi, Ebonyi, Imo, Jigawa, Kano, Nasarawa, Ondo and Oyo. As the programme
                progressed, additional 5 states which includes: Kaduna, Benue, Ekiti, Kwara and Kogi
                were added to the programme.
              </TextDesc>
              <TextDesc>
                {" "}
                Our goal was to enhance the ability of these Cooperatives & Trade Groups to manage
                and improve their financial transactions (internal savings and loans) to become an
                engine for even greater financial inclusion, integration of the un(der)banked into
                the formal financial system, capacity building to bridge knowledge and skill gap of
                groups and cooperatives, provision of digital financial services and linkage to
                affordable access to finance through provision of credit facilities from Riby’s
                partners.
              </TextDesc>
            </>
          }
        />

        {/* <Container> */}
        <ResultContainer>
          <Phead>Key Accomplishment Of The Project</Phead>
          <CardFlex>
            {finIncData.map((data, index) => (
              <ResultCard key={index}>
                <img src={data.img} alt="" />
                <h6>{data.title}</h6>
                <p>{data.desc}</p>
              </ResultCard>
            ))}
          </CardFlex>
        </ResultContainer>
        {/* </Container> */}
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "16.5rem;",
          }}
        >
          <Phead style={{ width: "60%" }}>
            Rebuilding The Financial Building Blocks For The People In The North-East
          </Phead>
        </div>

        <ProductCard
          img={ribyImg}
          ta
          // fd
          textDesc={
            <>
              <TextDesc>
                In 2021, Riby entered a partnership with Mercycorps Nigeria for the implementation
                of Feed the Future Nigeria Rural Resilience Activity (RRA) project across the states
                of North-Eastern states of Adamawa, Borno, Gombe and Yobe.
              </TextDesc>
              <TextDesc>
                The Project is meant to facilitate economic recovery and growth in vulnerable,
                conflict-affected areas by promoting systemic change in market systems. This will
                ensure long-term improvements to markets that will sustainably move over 90,000
                households out of chronic poverty.
              </TextDesc>
            </>
          }
        />
        <Phead>Partner With Us </Phead>
        <VideoContainer
          ta
          fd
          info={
            <div className="image3-container">
              <iframe
                // width="100%"
                // height="100%"
                className="iframes"
                // style={}
                // https://www.youtube.com/watch?v=jkXFtnuDlWU
                src="https://www.youtube.com/embed/jkXFtnuDlWU"
                title="financial inclusion video"
                frameBorder={0}
              ></iframe>
            </div>
          }
          textDesc={
            <TextHeader>
              <TextDesc>
                We understand that Financial inclusion is necessary for economic development and
                that’s why we launched the Riby 1.5 project in partnership with EFInA but there is
                more to be done.
              </TextDesc>
              <TextDesc>
                If you would like to partner with us in promoting financial inclusion you can reach
                us on the following channels:
              </TextDesc>
              <TextDesc
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "10px 0",
                  cursor: "pointer",
                  color: " #0f0738;",
                }}
              >
                {" "}
                <img src={mesBox} alt="" style={{ marginRight: "5px" }} />{" "}
                <Underline style={{ color: "#0f0738" }}>info@riby.me</Underline>
              </TextDesc>
              <TextDesc
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "10px 0",
                  cursor: "pointer",
                }}
              >
                {" "}
                <img src={phoneImg} alt="" style={{ marginRight: "5px" }} />
                <Underline style={{ color: "#0f0738" }} href="tel: +2347047166345">
                  012914247
                </Underline>{" "}
              </TextDesc>
              <TextDesc
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "10px 0",
                  cursor: "pointer",
                }}
              >
                {" "}
                <img src={whatsappIcon} alt="" style={{ marginRight: "5px" }} />
                <Underline style={{ color: "#0f0738" }} href="tel: +23480892222109">
                  +234 808 922 22109
                </Underline>
              </TextDesc>
            </TextHeader>
          }
        />
        <SupportWidget />
        <Footer />
      </Containers>
    </>
  );
};
export default Cooperative;

export const TextHeader = styled.div`
  margin-left: 2rem;

  @media (max-width: 800px) {
    margin-left: 2px;
  }
`;

const TextHead = styled.h4`
  margin: 0;
  padding: 0;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #0f0738;

  @media (max-width: 600px) {
    font-size: 1.2rem;
    margin-top: 20px;
  }
`;
const TextDesc = styled.p`
  margin: 0;
  padding: 0;
  line-height: 24px;
  font-size: 0.8rem;
  color: #0f0738 !important;
`;

const CardFlex = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-wrap: wrap;
`;

const ResultContainer = styled.section`
  width: 100%;
  height: 100%;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 2rem;
`;

const ResultCard = styled.div`
  width: 350px;
  height: 200px;
  border: 1px solid #d80c1b;
  background: transparent;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 7px;
  margin: 10px;
  transition: all 350ms;
  transform: scale(1);
  &:hover {
    border: 1px solid #c4c4c4;
    cursor: pointer;
    transform: scale(1.03);
  }

  h6 {
    color: #0f0738;
  }

  p {
    color: #0f0738;
  }
`;
