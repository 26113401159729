import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import { useState } from "react";

export const useUpload = () => {
  const [file, setFile] = useState("");
  const [loader, setLoader] = useState(false);
  const [progressBar, setProgressBar] = useState(0.0001);
  const [cv, setCv] = useState("");
  const uploadImg = async (e) => {
    let file = e.target.files[0];
    const saveFile = URL.createObjectURL(file);
    setFile(saveFile);
    // upload image to firebase/firestore
    // first import what we need, getStorage and ref
    // create a reference

    const storage = getStorage();
    const storageRef = ref(storage, "img/" + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        setProgressBar(progress);
        setLoader(false);
      },
      (error) => {
        console.log(error.message);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
          console.log("file available at", downloadUrl);
          setCv(downloadUrl);
        });
      }
    );
  };
  return { file, progressBar, uploadImg, cv, loader };
};
