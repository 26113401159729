import styled from "styled-components";

export const HeroImg = styled.img`
  width: 450px;
  margin-left: 50px;

  @media (max-width: 768px) {
    margin-left: 0;
    width: 350px;
    width: 100%;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  a {
    margin: 7px;
    margin-right: 1em;
  }
`;

export const HeroText = styled.div`
  width: 550px;
  // padding: 10px 30px;

  margin-right: 50px;
  @media (max-width: 1208px) {
    margin-right: 0;
    width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  h3 {
    font-family: Outfit;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    // line-height: 52px;
    color: #0f0738;
    @media (max-width: 768px) {
      font-size: 2.02rem;
      // line-height: 22px;
    }
  }
  p {
    color: #0f0738;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
  }
  @media (max-width: 768px) {
    margin-right: 0;
    padding: 10px 30px;
    width: 100%;
    font-family: Outfit;
    width: 100%;
  }
`;

export const Hero = styled.div`
  width: 100%;
  height: 100%;
  min-height: 70vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap-reverse;

  @media (max-width: 768px) {
    justify-content: space-around;
    margin-top: 0px;
    min-height: 100vh;
  }
`;
export const Containers = styled.div`
  width: 100%;
  height: 100%;
  min-height: 80vh;
`;

export const ProductInfo = styled.div`
  width: 100%;
  height: 100%;
  min-height: 70vh;
`;

export const ProductAction = styled.div`
  width: 100%;
  height: 100%;
  min-height: 70vh;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Content = styled.div`
  width: 80%;
  height: 100%;
  min-height: 50vh;
  background: rgba(13, 34, 61, 0.5);
  display: flex;
  justify-content: space-around;
  border-radius: 5px;
  align-items: center;
  padding: 20px;
  flex-wrap: wrap-reverse;
  margin: 20px 10px;
`;

export const TextContent = styled.div`
  width: 500px;
  color: white;
  h2 {
    color: #ffffff;
  }
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

export const SavemoreContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 70vh;
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;
`;

export const SaveMoreWraper = styled.div`
  width: 90%;
  height: 100%;
  min-height: 60vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  border-radius: 7px;

  @media (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap-reverse;
  }

  @media (max-width: 1008px) {
    width: 100%;
    flex-wrap: wrap-reverse;
  }
`;

export const SavemoreText = styled.div`
  width: 650px;
  background-color: #0f0738;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  padding: 20px;

  h2 {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;

    color: #ffffff;
  }
  p {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    color: #ffffff;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 1008px) {
    width: 100%;
  }
`;
export const Dashboard = styled.img`
  width: 700px;
  height: 60vh;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 1008px) {
    width: 100%;
  }
`;
