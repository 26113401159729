import { useEffect } from "react";
import styled from "styled-components";
import { Containers, Hero } from "../CooperativeManagement/style";
import { ProductCard } from "../CardContainer";
import Fade from "react-reveal/Fade";
import SupportWidget from "../../../component/SupportWidget";
import Footer from "../../../component/Footer";
import Navbar from "../../../component/Navbar";
import agImg from "../../../image/financialImg1.png";
import agImg2 from "../../../image/agentIMG.jpeg";
import agImg3 from "../../../image/financialimg3.png";
import { Helmet } from "react-helmet";
import { Button } from "../../../component/Button";
import { useNavigate } from "react-router-dom";

const AgentBusiness = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Agent Banking - Riby";
  }, []);

  return (
    <>
      <Navbar img="/Logo.png" />
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Agent Network Banking - We have agents trained and equipped to provide micro banking
          services within location and communities
        </title>
        <meta
          name="description"
          content="Riby Cobanking is winning the race to becoming Nigeria's best cooperative software
          solution. You can grow your cooperative members, carryout thrift collection activities, as
          well as managing loan disbursement and repayment. Get started"
        />
      </Helmet>
      <Containers>
        <Fade top>
          <Hero>
            <h3>Agent Network Business</h3>
            <p>
              Our Agents are trained and equipped to provide micro banking services within their
              location or community. This is aimed at providing value and service offering to boost
              revenue generation for those interested in working with Riby.
            </p>
          </Hero>
        </Fade>

        <ProductCard
          ta
          fd
          img={agImg2}
          textHead={<TextHead>Cooperative Agents</TextHead>}
          textDesc={
            <>
              <TextDesc>
                These are Agents carrying out Cooperatives services, these includes:
              </TextDesc>
              <ul>
                <Li>Cooperative identification and profiling </Li>
                <Li>Cooperative branding and website development</Li>
                <Li>Cooperative and member registration and on-boardings</Li>
                <Li>Savings collection </Li>
                <Li>Loan booking</Li>
                <Li>Loan repayment</Li>
              </ul>
              <TextDesc style={{ marginBottom: "1rem" }}>
                Has your cooperative lost money because your field agents ran away? Riby Cooperative
                Agent Network allow cooperatives to manage thrift collection smoothly, thereby
                increasing cooperative membership and contributions. This application is an icing on
                the cake if you already signed up on Cobanking.
              </TextDesc>
              <Button title="Get Started" onClick={() => navigate("registercooperative")} />
            </>
          }
        />

        <ProductCard
          ta
          img={agImg3}
          textHead={<TextHead>Banking Agents</TextHead>}
          textDesc={
            <>
              <TextDesc>These are Agents that carry out banking services which includes:</TextDesc>
              <ul>
                <Li>Cash out (Withdrawal) </Li>
                <Li>Cash in (Deposit)</Li>
                <Li>Bills payment</Li>
              </ul>
            </>
          }
        />
        <ProductCard
          ta
          fd
          img={agImg}
          textHead={<TextHead>Agency Services</TextHead>}
          textDesc={
            <>
              <TextDesc>
                We have on the ground network of trained and skilled Agents, offering value and
                support to customers across Nigeria far beyond the reaches of technology. These
                services include:
              </TextDesc>
              <ul>
                <Li>Address verification </Li>
                <Li>Market penetration</Li>
                <Li>Field research</Li>
                <Li>Registrations and Enumeration </Li>
              </ul>
            </>
          }
        />
        <SupportWidget />
        <Footer />
      </Containers>
    </>
  );
};
export default AgentBusiness;

export const TextHead = styled.h4`
  margin: 0;
  padding: 0;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #0f0738;

  @media (max-width: 600px) {
    font-size: 1.2rem;
    margin-top: 20px;
  }
`;
export const TextDesc = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #0f0738 !important;
`;

export const Li = styled.li`
  margin: 0;
  padding: 0;
  font-size: 1rem;
  color: #0f0738;
`;
