import Navbar from "../../../component/Navbar";
import Fade from "react-reveal/Fade";
import SupportWidget from "../../../component/SupportWidget";
import Footer from "../../../component/Footer";
import { Helmet } from "react-helmet";
import "./faq.css";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import img1 from "../../../image/faqImg/Picture1.jpg";
import img2 from "../../../image/faqImg/Picture2.png";
import img3 from "../../../image/faqImg/Picture3.png";
import img4 from "../../../image/faqImg/Picture4.png";
import img5 from "../../../image/faqImg/Picture5.png";
import img6 from "../../../image/faqImg/Picture6.png";
import img7 from "../../../image/faqImg/Picture7.png";
import img7a from "../../../image/faqImg/Picture8.png";
import img8 from "../../../image/faqImg/adminwithdraw.png";
import img9 from "../../../image/faqImg/Picture9.png";
import img10 from "../../../image/faqImg/Picture10assign.png";
import img11 from "../../../image/faqImg/Picture10contribution.png";
import img12 from "../../../image/faqImg/Picture10loan.png";
import img15 from "../../../image/faqImg/Picture10loans2.png";
import img13 from "../../../image/faqImg/Picture10withrequest1.png";
import img14 from "../../../image/faqImg/Picture10withrequest2.png";
import img16 from "../../../image/faqImg/Picture10assign2.png";
import img17 from "../../../image/faqImg/Picture10contributionmangement.png";
import img18 from "../../../image/faqImg/bulk1.png";
import img19 from "../../../image/faqImg/bulk2.png";
import img20 from "../../../image/faqImg/bulk3.png";
import img21 from "../../../image/faqImg/bulk4.png";
import { useEffect } from "react";

const Faq = () => {
  return (
    <>
      <Navbar img={"/Logo.png"} />
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>FAQ - RIBY COBANKING</title>
          <description> </description>
          <meta
            name="description"
            content="our software provides solutions for cooperative and thrift -  Having problems with CoBanking platform?"
          />
        </Helmet>
        <div className="hero-section">
          <div className="faqHero">
            <Fade top>
              <h1>CoBanking FAQ</h1>
            </Fade>
          </div>
        </div>
        <Container>
          <Wrapper>
            <h1 className="header">What you need to know about CoBanking</h1>
            <FlexContent>
              <Content>
                <h3>What is Riby CoBanking?</h3>
                <p>
                  Riby Cobanking is a digital platform built to manage cooperative activities; i.e.
                  for your cooperative members, monitor member’s cash contribution, manage loan
                  application processes with automated modules
                </p>
              </Content>
            </FlexContent>
            <FlexContent>
              <Content>
                <h3>What channels can your solutions be accessed?</h3>
                <p>
                  Mobile Phone (Android Mobile): You can access our solutions using your Android
                  mobile device by going to the to download the Riby CoBanking App.
                  <a
                    href="https://play.google.com/store/apps/details?id=ng.riby.rcb"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Play Store
                  </a>
                </p>
                <p>
                  Computer: You can access our solutions via web using our URL on
                  <a href="www.riby.ng">www.riby.ng</a>
                </p>
                <p>
                  USSD CODE: Using your phone you can dial our USSD code to access our platform.
                  <a href="tel: *347*66#"> *347*66#</a>
                </p>
              </Content>
              <img src={img1} alt="" />
            </FlexContent>
            <FlexContent>
              <Content>
                <h3>How do I get started?</h3>
                <ul>
                  <li>
                    Log on to <a href="www.accounts.riby.ng">www.accounts.riby.ng</a> on your
                    device, to get started on the web. Log on to{" "}
                    <a href="www.accounts.riby.ng">www.accounts.riby.ng</a> on your device, To get
                    started on the web.
                  </li>
                  <li> Click on the URL and launch the domain.</li>
                  <li>
                    Next you login your cooperative accounts information to access the Riby Co
                    Banking platform.
                  </li>
                </ul>
                <p>
                  NOTE: This is applicable to only existing customers on our platform. Prospects
                  should contact <a href="mailto:sales@riby.ng">sales@riby.ng</a> get onboarded.
                </p>
              </Content>
              <ImgContainer>
                <img src={img2} alt="" />
                <img src={img3} alt="" />
                <img src={img4} alt="" />
              </ImgContainer>
            </FlexContent>
            <FlexContent>
              <Content>
                <h3>Access Product</h3>
                <li>
                  Upon a successful sign in, go the side bar and click on{" "}
                  <strong>Riby cooperative banking platform</strong> and access product.
                </li>
                <li>
                  You’ll get a <strong>switch pop-up</strong> after signing in, click on{" "}
                  <strong>‘SWITCH’</strong> to the new version of the platform.
                </li>
              </Content>
              <ImgContainer>
                <img src={img5} alt="" />
                <img src={img6} alt="" />
              </ImgContainer>
            </FlexContent>
            <FlexContent>
              <Content>
                <h3>The Dash Board</h3>

                <ul>
                  <li>
                    The Dash board shows your summary at a glance. Explaining your overall and total
                    contributions, loans, members, overall outstanding etc.
                  </li>
                  <li>
                    By the left side on your dashboard you find modules to navigate you easily
                    around the platform. Which has the admin and members options for different and
                    easy navigation.
                  </li>
                </ul>
              </Content>
              <ImgContainer>
                <img src={img7} alt="" />
                <img src={img7a} alt="" />
              </ImgContainer>
            </FlexContent>
            <FlexContent>
              <Content>
                <h3>Member Profile</h3>
                <p>
                  <strong>Profile:</strong>
                  Shows the details of the member
                </p>
                <p>
                  <strong>Billings:</strong>
                  Member gets to add their card/bank details for seamless payment transactions.
                </p>
                <p>
                  <strong>Wallet:</strong>
                  Holds your funds to enable payment of contributions and loan repayment.
                </p>
                <p>
                  <strong>Switch:</strong>
                  Upon signing in, system would ask that you switch to the new version as it
                  contains all the feature needed to navigate you on the platform.
                </p>
                <p>
                  <strong>Logout:</strong>
                  You can logout of the platform when you are done with all your transaction.
                </p>
              </Content>
              <img className="row-img" src={img9} alt="" />
            </FlexContent>
            <FlexContent>
              <Content>
                <h3>Contribution Modules</h3>
                <p>
                  Contribution modules comprises of three buttons; Click on the drop down and to see
                  the following features
                  <ui>
                    <li>
                      My Contributions: This contribution shows the number and types of
                      contributions that you as a member have saved..
                    </li>
                    <li>
                      My Subscriptions: This shows the number and type of contribution you have
                      subscribed.
                    </li>
                    <li>
                      Contribution Types: This shows the number of contribution types that has been
                      created by the Admin. As a member you get to choose the contribution type you
                      want to save towards.
                    </li>
                  </ui>
                </p>
                <div>
                  <h5>How do I save on the platform?</h5>
                  <span>
                    To save on the platform simply go to <strong>Contribution types</strong> to get
                    started.
                  </span>
                  <span>Still having problems, watch tutorial on </span>
                  <a
                    href="https://youtu.be/NGcMqe9XM3M?list=PLALU1QmbKUXOQ9fdQQPDyQ2jj6yHE9o8D"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    youtube to get more details
                  </a>
                </div>
                <div>
                  <h5> How do I track my subscriptions?</h5>
                  <p>
                    To track your subscriptions click on the <strong>My subscriptions</strong>
                  </p>
                  <span>Still having problems, watch tutorial on </span>
                  <a
                    href="https://youtu.be/v4YL3SXtkG0?list=PLALU1QmbKUXOQ9fdQQPDyQ2jj6yHE9o8D"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    youtube
                  </a>
                </div>
                <div>
                  <h5> How do I track my contribution?</h5>
                  <p>
                    To track your contributions, click on <strong>My Contributions</strong> to view
                    the contributions you save towards
                  </p>
                  <span>Still having problems, watch tutorial on </span>
                  <a
                    href="https://youtu.be/F94CvliYXwI?list=PLALU1QmbKUXOQ9fdQQPDyQ2jj6yHE9o8D"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    youtube to get more details
                  </a>
                </div>
              </Content>
              <img src={img11} alt="img" />
            </FlexContent>
            <FlexContent>
              <Content>
                <h3>Loan Modules</h3>
                <div>
                  <p>
                    Loan Types:: This shows you the different types of loan that is available for
                    the members, that has been created by the Admin.
                  </p>
                  <h5>How do I apply for a loan type</h5>
                  <p>
                    To apply for a loan simply go to loan types to know the variousloans that are
                    availale
                  </p>
                  <span>Still having problems, watch tutorial on </span>
                  <a
                    href=" https://youtu.be/zRdc3CTZvyc?list=PLALU1QmbKUXOQ9fdQQPDyQ2jj6yHE9o8D"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    youtube to get more details
                  </a>
                </div>
                <div>
                  <h5>My Application</h5>
                  <p>This shows the status of the loan application either approved or rejected</p>
                </div>
                <div>
                  <h5>History</h5>
                  <p>
                    This shows you information on all the loans applied for overtime on the platform
                  </p>
                </div>
                <div>
                  <h5>Guarantor's Request</h5>
                  <p>
                    This shows that an applicant within the cooperative that has selected you as a
                    guarantor
                  </p>
                </div>
                <div>
                  <h5>How do I know if my loans have been approved</h5>
                  <p>
                    To know if your loans have been approved click on the{" "}
                    <strong>My Application</strong> modules
                  </p>
                  <span>Still having problems, watch tutorial on </span>
                  <a
                    href=" https://youtu.be/zKvlxVo3OWM?list=PLALU1QmbKUXOQ9fdQQPDyQ2jj6yHE9o8D"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    youtube to get more details
                  </a>
                </div>
                <div>
                  <h5>How do I reject or accept a guarantors request</h5>
                  <p>
                    To accept or reject, click on the loan modules dropdown to navigate you to{" "}
                    <strong>Guarantor's Request to confirm the request</strong>
                  </p>
                  <span>Still having problems, watch tutorial on </span>
                  <a
                    href="  https://youtu.be/IETvuh52gDg?list=PLALU1QmbKUXOQ9fdQQPDyQ2jj6yHE9o8D"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    youtube to get more details
                  </a>
                </div>
                <div>
                  <h5>How do I repay my loans?</h5>
                  <p>
                    Click the link{" "}
                    <a href="https://youtu.be/C4Y9OnADw0I" target={"_blank"} rel="noreferrer">
                      https://youtu.be/C4Y9OnADw0I
                    </a>{" "}
                  </p>
                </div>
              </Content>
              <img src={img12} alt="" />
            </FlexContent>
            <FlexContent>
              <Content>
                <h3>Loans</h3>
                <p>
                  As a cooperative member, how do I access loan from my cooperative?
                  <ul>
                    <li>
                      As a member, go loan type and pick a loan you want to access and apply towards
                      it. For more information contact your cooperative admin.
                    </li>
                  </ul>
                </p>
              </Content>
              <img src={img15} alt="" />
            </FlexContent>
            <FlexContent>
              <Content>
                <h3>Withdrawal Request Module</h3>
                <div>
                  <h5>How do I apply for a withdrawal?</h5>
                  <p>
                    Click on withdrawal request as shown on the image and navigate to your
                    contribution wallet then <strong>click</strong> on action button and request to
                    withdraw from your saved contribution.
                  </p>
                </div>
                <div>
                  <h5>How do I track my withdrawal request?</h5>
                  <p>
                    To track this, click on the dropdown and navigate to withdrawal request to check
                    if your request has been approved by your admin.
                  </p>
                </div>

                <div>
                  <h5>
                    What should I do if I experience withdrawal transaction error on the Platform?
                  </h5>
                  <p>
                    As a member, you can experience withdrawal transaction error for these reasons;
                    <ul>
                      <li>
                        An attempt to withdraw from a contribution wallet you are not saving
                        towards.
                      </li>
                      <li>
                        Attempting to withdraw from a non withdrawable wallet, in such case contact
                        your cooperative Admin.
                      </li>
                      <li>Insufficient funds in your contribution wallet</li>
                    </ul>
                  </p>
                </div>
                <div>
                  <h5>Contibution wallet</h5>
                  <p>
                    This informs your decision on which contribution type you can withdraw from.
                  </p>
                </div>
                <div>
                  <h5>How do I know which contribution is withdrawable or non withdrawable?</h5>
                  <p>To confirm this click on the contribution wallets dropdown.</p>
                </div>
              </Content>
              <ImgContainer>
                <img src={img13} alt="" />
                <img src={img14} alt="" />
              </ImgContainer>
            </FlexContent>
            <h2>ADMIN’S MODULE</h2>
            <FlexContent>
              <Content>
                <h3>Member Onboarding</h3>
                <h4>How do I onboard my cooperative members?</h4>
                <p>
                  To add new members to your cooperative on RCB, as an admin,
                  <ul>
                    <li>
                      Click on the <strong>user management module</strong>
                    </li>
                    <li>
                      on the drop down list click on <strong>Members</strong>
                    </li>
                    <li>
                      From here click on <strong>New</strong>
                    </li>
                    <li>
                      You can use Single upload to accommodate one member and at other times, Bulk
                      upload accommodate multiple entry. For more visual details, click the link:
                      <a href="https://youtu.be/h7WnKlTway0" target={"_blank"} rel="noreferrer">
                        https://youtu.be/h7WnKlTway0
                      </a>
                    </li>
                  </ul>
                </p>

                <p>
                  What are the charges?
                  <ul>
                    <li>
                      The cooperative or group is charged per member and monthly based on
                      cooperative membership strength. There are different payment plan i.e
                      monthly/quarterly/ biannual/ annually.
                    </li>
                  </ul>
                </p>
              </Content>
              <img src={img10} alt="" />
            </FlexContent>
            <FlexContent>
              <Content>
                <h3>How do I assign roles to some of my admins?</h3>
                <p>
                  As an Admin of a cooperative, you can assign roles and permission to other admins
                  in your cooperative. This way they have privileges to be able to navigate around
                  the platform.
                  <ul>
                    <li>
                      Go to <strong>user management </strong>
                    </li>
                    <li>
                      click on <strong>Members</strong>
                    </li>
                    <li>search members information</li>
                    <li>Go to action button as against the member's name</li>
                    <li>Click on edit </li>
                    <li>Go cooperative information </li>
                    <li>
                      {" "}
                      You can then assign roles for your members and also choose the privilege you
                      want them to have on the platform.
                    </li>
                  </ul>
                </p>
              </Content>
              <ImgContainer>
                <img src={img10} alt="" />
                <img src={img16} alt="" />
              </ImgContainer>
            </FlexContent>
            {/* */}
            <FlexContent>
              <Content>
                <h2>CONTRIBUTION MANAGEMENT</h2>
                <p>Contribution management has two drop down</p>
                <p>
                  Contributions is the tracking sheet that shows a particular savings/contribution
                  made by the members.
                </p>
                <p>
                  Types: These are different contributions created by the Admin and its visible to
                  the members to make their contributions
                </p>
                <p>
                  How do I track member’s contribution via the platform?
                  <ul>
                    <li>
                      Member’s payment done via the platform can be verified by clicking on
                      contribution management dropdown, then go to contribution to confirm.
                    </li>

                    <span>Still having problems, watch tutorial on </span>
                    <a
                      href=" https://youtu.be/h7WnKlTway0?list=PLALU1QmbKUXOQ9fdQQPDyQ2jj6yHE9o8D"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      youtube to get more details
                    </a>
                  </ul>
                </p>
                <p>
                  How do I get a record of member’s that do not pay via the platform?
                  <ul>
                    <li>
                      When a payment is done off the platform, Admins will have to manually input
                      the payment transaction by going to “contribution management” and click on the
                      dropdown of contributions to manually add member’s contribution transactions
                      to the platform, an SMS will then be generated and sent to members/admin for
                      verification/record keeping purposes.
                    </li>
                  </ul>
                </p>
              </Content>
              <img src={img17} alt="" />
            </FlexContent>
            <FlexContent>
              <Content>
                <h2>Loan Management</h2>

                <p>
                  As a cooperative Admin, how do I create loan types?
                  <ul>
                    <li>
                      As an admin, go to the loan management module under administrative as shown in
                      the image, click on type and start creating.
                    </li>
                  </ul>
                </p>
                <p>
                  As an admin of a cooperative, how do we access external loans?
                  <ul>
                    <li>
                      Go to cooperative management and click on profile and click on{" "}
                      <strong>disable external loans</strong> to enable your access to external
                      loans and save changes . Thereafter go to Loan management, click on loans and
                      access external loans.
                    </li>
                  </ul>
                </p>
              </Content>
              <ImgContainer>
                <img src={img12} alt="" />
                <img src={img15} alt="" />
              </ImgContainer>
            </FlexContent>
            <FlexContent>
              <Content>
                <h2>Withdrawal upload</h2>

                <p>
                  How do I upload withdrawals?
                  <ul>
                    <li>
                      To upload a withdrawal as an admin, a member must have requested for a
                      withdrawal first which will have to be approved by an admin which enables a
                      successful withdrawal from the correct contribution or savings type.
                    </li>
                  </ul>
                </p>
                <p>
                  What should I do as an admin when I get an error message of PENDING TRANSACTION
                  warning, whilst trying to upload a withdrawal manually?
                  <ul>
                    <li>
                      Ensure that all pending withdrawal request have been cleared and also reload
                      the page to start transaction. If this persist reach out to your support team
                      at <a href="mailto:support@riby.ng">support@riby.ng</a>
                    </li>
                  </ul>
                </p>
              </Content>
              <ImgContainer>
                <img src={img8} alt="" />
              </ImgContainer>
            </FlexContent>
            <FlexContent>
              <Content>
                <h2>BULK UPLOAD</h2>

                <p>
                  This module enables the admin to onboard all your cooperative members in one go.
                  To start, click on user management module then click on members feature, after
                  that click on the new member button found at the top right corner of your screen,
                  click on bulk upload and then click upload file to upload a CSV file or excel
                  sheet coupled with the correct information of your members. Upon this members will
                  be onboarded automatically into the platform.
                </p>
                <p>Upon this members will be onboarded automatically into the platform.</p>
              </Content>
              <ImgContainerRow>
                <div className="flex1">
                  <img className="row-img" src={img18} alt="" />
                  <img className="row-img" src={img19} alt="" />
                </div>
                <div className="flex1">
                  <img className="row-img" src={img20} alt="" />
                  <img className="row-img" src={img21} alt="" />
                </div>
              </ImgContainerRow>
            </FlexContent>
          </Wrapper>
        </Container>
      </div>
      <SupportWidget />
      <Footer />
    </>
  );
};
export default Faq;

export const ImgContainerRow = styled.div`
  .flex1 {
    display: flex;

    justify-content: center;

    @media (max-width: 600px) {
      flex-wrap: wrap;
    }
  }
  .row-img {
    width: 250px;

    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  h3,
  h4,
  h2,
  span {
    color: #0f0a38 !important;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    margin: 0;
    padding: 0;
  }
  p {
    margin: 0;
    padding: 0;
  }
  h5 {
    padding-top: 2rem !important;
  }
  a {
    cursor: pointer;
  }
`;

const FlexContent = styled.div`
  display: flex;

  justify-content: space-around;

  align-items: flex-start;
  margin: 3rem 1rem;
  h1,
  h2,
  h3.h4,
  h5,
  h6,
  p {
    color: #0f0a38;
  }

  img {
    width: 400px;
    background: transparent;
    margin: 0.5rem;
    @media (max-width: 600px) {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin-top: 2rem;

  h1,
  h2,
  h3.h4,
  h5,
  h6 {
    color: #0f0a38;
  }

  .header {
    text-align: center;
  }

  a {
    cursor: pointer;
  }
`;
