import styled from "styled-components";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { useState } from "react";
const FaqAccordion = ({ data }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };
  return (
    <Container onClick={handleShow}>
      <h5>
        {data.number}.{data.question}
      </h5>
      {show && (
        <>
          <p>{data.answer}</p>
          {data.otherAnswer?.map((data, i) => (
            <ul key={i} style={{ listStyleType: "lower-latin" }}>
              <li>{data}</li>
            </ul>
          ))}
        </>
      )}
      <Icon>
        {show ? (
          <IoIosArrowDown style={{ fontSize: "20px" }} />
        ) : (
          <IoIosArrowForward style={{ fontSize: "20px" }} />
        )}
      </Icon>
    </Container>
  );
};
export default FaqAccordion;

const Container = styled.div`
  width: 70%;
  min-height: 50px;
  height: 100%;
  cursor: pointer;
  margin: 10px;
  position: relative;
  border-radius: 10px;
  border: 1px solid lightgray;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 0.625rem;
  padding-right: 2.5rem;
  text-align: left;
  transition: all 0.3s ease-in-out;
  h5 {
    padding: 0;
    margin: 0;
    font-weight: 500;

    text-align: left !important;
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
  p {
    color: black;
    transition: all 0.2s linear;
  }
  @media (max-width: 768px) {
    width: 80%;
    font-size: 0.7rem;
    text-align: left !important;
    margin: 1rem 1.5rem;
  }
  @media (max-width: 460px) {
    width: 80%;
    // padd
    font-size: 0.5rem;
    text-align: left !important;
  }

  ul {
    // padding: 0;
    // margin: 0;
  }
  li {
    padding: 0;
    margin: 0;
  }
`;

const Icon = styled.div`
  position: absolute;
  right: 20px;
  top: 15px;
`;
