import styled from "styled-components";
import Fade from "react-reveal/Fade";

export const ProductCard = ({ fd, ta, fs, img, textHead, textDesc, btn, info }) => {
  return (
    <Container>
      <Wrapper fd={fd}>
        <Fade left>
          <TextContent ta={ta}>
            <span>{textHead}</span>
            <p fs={fs}>{textDesc} </p>
            {btn}
          </TextContent>
        </Fade>
        <Fade right>
          <div>
            <Image src={img} alt="" />
            {info}
          </div>
        </Fade>
      </Wrapper>
    </Container>
  );
};

export const VideoContainer = ({ fd, ta, fs, img, textHead, textDesc, btn, info }) => {
  return (
    <Container>
      <VideoWrapper fd={fd}>
        <Fade left>
          <TextContent ta={ta}>
            <span>{textHead}</span>
            <p fs={fs}>{textDesc} </p>
            {btn}
          </TextContent>
        </Fade>
        <Fade right>
          <div>
            <Image src={img} alt="" />
            {info}
          </div>
        </Fade>
      </VideoWrapper>
    </Container>
  );
};

// export const Container = styled.div``;
// export const Container = styled.div``;
export const TextContent = styled.div`
  width: 650px;
  margin-left: 3em;
  margin-right: 3em;
  color: #0f0738;
  text-align: ${({ ta }) => (ta ? "left" : "right")};
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 2px;
    margin-right: 2px;
  }

  span {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    color: #0f0738;
  }

  p {
    // font-size: ${({ fs }) => fs};
    font-size: 16px;
    color: #0f0738;
    line-height: 24px;
  }

  @media (max-width: 1200px) {
    width: 450px;
  }
  @media (max-width: 900px) {
    width: 350px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const Image = styled.img`
  width: 500px;
  border-radius: 10px;
  margin: 5px 3em 5px 3em;
  @media (max-width: 1200px) {
    width: 450px;
    margin: 0;
  }
  @media (max-width: 900px) {
    width: 350px;
    margin: 0;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

export const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ fd }) => (fd ? "row" : "row-reverse")};
  margin: 20px 5px;
  flex-wrap: wrap-reverse;
  padding: 10px;

  @media (max-width: 768px) {
    display: flex;
    margin: 0;
    justify-content: center;
  }
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ fd }) => (fd ? "row" : "row-reverse")};
  margin: 20px 5px;
  flex-wrap: wrap-reverse;
  padding: 10px;

  @media (max-width: 768px) {
    margin: 0;
    justify-content: center;
  }
`;
export const Container = styled.div``;
