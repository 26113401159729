import "./style.css";
const Maps = () => {
  return (
    <div className="maps">
      <div className="mapouter">
        <div className="gmap_canvas">
          <iframe
            width="400"
            height="500"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=Block%20G,%20House%204,%20Moore%20Road,%20Yaba,%20Lagos&t=&z=13&ie=UTF8&iwloc=&output=embed"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            title="maps"
            className="maps"
          ></iframe>
        </div>
      </div>
    </div>
  );
};
export default Maps;

// {/* <style>.gmap_canvas {overflow:hidden;background:none!important;height:500px;width:600px;}</style>
// <style>.mapouter{position:relative;text-align:right;height:500px;width:600px;}</style> */}
