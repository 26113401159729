import { dbstore } from "../firebase";
import { addDoc, collection } from "firebase/firestore";
import { useState } from "react";
// import { useHistory } from "react-router-dom";

export const useApply = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [success, setSuccess] = useState(false);
  //   const history = useHistory();

  const applyJob = async (name, email, phone, role, cv) => {
    setIsLoading(true);
    try {
      const collectionRef = collection(dbstore, "application");
      const payload = { name, email, phone, role, cv, view: false };
      await addDoc(collectionRef, payload);
      setSuccess(true);
      setIsLoading(false);
      //   history.push("/");
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
      setErr("An error occured during operation");
      setSuccess(false);
    }
  };
  return { isLoading, err, applyJob, success };
};
