import salami from "../image/aboutfounder.png";
import finicon1 from "../image/finicon1.png";
import fiiicon2 from "../image/fiicon2.png";
import finicon3 from "../image/finicon3.png";
import finicon4 from "../image/fiiImg4.png";
import finicon5 from "../image/fiiImg5.png";
import richmond from "../image/staff/richmondImg.jpeg";
import kiyesi from "../image/staff/kiyesi.png";
import temitayo from "../image/staff/temitayo.jpeg";
import jumoke from "../image/staff/jumoke.jpeg";
import miracleImg from "../image/staff/miracleImg.jpeg";
import afolabiImg from "../image/staff/afolabiImg.jpeg";
import sundayImg from "../image/staff/sundayImg.jpeg";
import susanImg from "../image/staff/susan.jpeg";
import femiImg from "../image/staff/femi.jpeg";
import segunImg from "../image/staff/segun.jpeg";
export const Members = [
  {
    image: salami,
    memberName: "Salami Abolore",
    position: "Founder",
    link: "https://www.linkedin.com/in/aboloresalami",
  },
  {
    image: kiyesi,
    memberName: "Dayo Kiyesi",
    position: "VP, Retail Business",
    link: "https://www.linkedin.com/in/dayo-kiyesi-0b6a1447",
  },
  {
    image: richmond,
    memberName: "Richmond Oghenedoro",
    position: "VP, Business Operations",
    link: "https://www.linkedin.com/in/richmond-oghenedoro",
  },
  {
    image: jumoke,
    memberName: "Olajumoke Akinwale",
    position: "Product Manager",
    link: "https://www.linkedin.com/in/olajumoke-a-3a7879b9",
  },
  {
    image: afolabiImg,
    memberName: "Afolabi Salami",
    position: "Head, Growth & Programmes",
    link: "https://www.linkedin.com/in/afolabi-salami-039b41100",
  },
  {
    image: miracleImg,
    memberName: "Miracle Chime",
    position: " Lead, Marketing & Comms.",
    link: "https://www.linkedin.com/in/miraclechime",
  },
  {
    image: susanImg,
    memberName: "Susan Obong",
    position: "Lead, Cooperative Business",
    link: "https://www.linkedin.com/in/susan-obong-077a9036",
  },
  {
    image: sundayImg,
    memberName: "Sunday Fadipe",
    position: "Head, Legal & Compliance",
    link: "https://www.linkedin.com/in/sunday-fadipe-4104b977",
  },

  {
    image: segunImg,
    memberName: "Segun Adetona",
    position: "Lead, Agent Network",
    link: "https://www.linkedin.com/in/olusegun-matthew-adetona-902a72131",
  },
  {
    image: temitayo,
    memberName: "Temitayo Isiaka",
    position: " Lead, People Operations",
    link: "https://www.linkedin.com/in/temitayo-isiaka-08630131",
  },

  {
    image: femiImg,
    memberName: "Femi Ayo-Owolabi",
    position: "Lead, Financial Services ",
    link: "https://www.linkedin.com/in/oluwafemi-ayo-owolawi-0a8770150",
  },
];

export const finIncData = [
  {
    img: finicon1,
    title: "1Million+",
    desc: "Customers engaged and 3,000+ cooperatives and trade groups with over 250,000 members.",
  },
  {
    img: fiiicon2,
    title: "40%",
    desc: "Of the customers had never been integrated into any formal banking system",
  },
  {
    img: finicon3,
    title: "25 States",
    desc: "Expansion across Nigeria",
  },
  {
    img: finicon5,
    title: "416,454",
    desc: "Customers with 290,898 being female customers that were included in the financial services stream for the first time.",
  },
  {
    img: finicon4,
    title: "4,037",
    desc: "Engaged agents who are mostly youth across Nigeria carrying out financial services in their communities.",
  },
];
