import styled from "styled-components";
import { WhatsAppOutlined } from "@ant-design/icons";
import { useState } from "react";

import React from "react";
import { useEffect } from "react";

const Link = "https://wa.link/pva8dc";

export default function SupportWidget() {
  return (
    <Widget href={Link} target="_blank" rel="no_referrer">
      <WhatsAppOutlined />
      Message us on Whatsapp
    </Widget>
  );
}

const Widget = styled.a`
  z-index: 1;
  position: fixed;
  display: flex;
  align-items: center;
  place-items: center;
  justify-content: center;
  gap: 0.5rem;
  bottom: 0;
  margin-bottom: 1rem;
  margin-right: 1rem;
  right: 0;
  padding: 10px;
  width: 200px;
  height: fit-content;
  white-space: nowrap;
  background-color: green;
  border-radius: 1rem 1rem 0 1rem;
  box-shadow: 0 0 5px rgb(0, 0, 0, 0.2);
  color: white;

  @media (max-width: 450px) {
    margin-right: 0;
    right: 5px;
    // display: none;
  }

  @media (max-width: 350px) {
    margin-right: 0;
    right: 2px;
    // display: none;
  }
`;
