import React, { useEffect } from "react";
import "./Terms.css";
import Navbar from "../../component/Navbar";

const Terms = () => {
  useEffect(() => {
    document.title = "Riby - Terms of Use ";
  }, []);
  return (
    <>
      <Navbar img={"/Logo.png"} />
      <div className="terms-container">
        <h4>TERMS OF USE</h4>
        <p>
          This Terms of Use is a binding contract between Riby Marketplace Limited (RIBY) referred
          to as "Company", "we", "us" and "our" AND You, our Client (User of Riby CoBanking and our
          other relevant products and services) hereinafter referred to as "You" or “Client”.
        </p>
        <p>
          You agree to be bound by this contract and to receive marketing and promotional
          information from us upon signing up for our service.
        </p>
      </div>

      <div className="terms-container">
        <p>1. Service</p>
        <p>
          We provide a Cooperative Management Platform ("Riby CoBanking") (“RCB) ("The Service").
          Riby CoBanking consists of a web application, mobile applications and Unstructured
          Supplementary Service Data (USSD). Riby CoBanking is used among cooperative societies,
          savings groups and associations, to manage their contribution, loan processes, as well as
          their database. We are responsible for the quality, delivery, and maintenance of RCB in
          compliance with our Service Level Agreement and/or this Terms of Use.
        </p>
        <p>2. Commencement and Duration </p>
        <p>
          This Terms of Use shall be effective from the date of signing of the Service Level
          Agreement (SLA) between us and You or where no SLA is signed, upon registering for any of
          our Services and shall automatically renew each year.
        </p>
        <p>3. Subscription</p>
        <p>
          3.1 Cooperatives or Savings groups pay a subscription of N100 or N50 fee per member,
          depending on their member-strength. Through the subscription, they have access to all the
          channels available. Each cooperative or group will also bear its costs of transactions.
        </p>
        <p>
          3.2 The Service shall be made available to you subject to your subscription plan. Any
          variation in the rate of your subscription plan will be communicated to you at least 24
          hours before such rate becomes operational.
        </p>
        <p>
          3.3 The subscription cost, transaction fee and other relevant charges paid by You are not
          refundable.
        </p>
        <p>
          3.4 Whatsoever claim you may have against us cannot be set off against any payment due to
          us nor withheld from any payment due to us from any invoice.
        </p>
        <p>4. Warranties and Liability</p>
        <p>
          4.1 The use of Riby CoBanking comes with no warranty, express or implied, including, but
          not limited to, warranties of profitability, merchantability or fitness for a particular
          purpose except as expressly provided in this Terms of Use or our SLA with You
        </p>
        <p>
          4.2 Riby shall not be liable to you for any special, consequential, direct, or indirect
          damages for loss of revenue, profits, business, data or whatsoever thing, whether arising
          from negligence, breach of contract or otherwise, save that in any instance, the total
          contractual and non-contractual liability of Riby shall be limited to any payment made to
          us in the previous month.
        </p>
        <p>5. Indemnity</p>
        <p>
          You confirm and agree to indemnify, defend and hold us harmless and any of our agents,
          officers, employees, directors, and affiliate companies from and against any and all
          losses, claims, expenses, damages, proceedings, actions, liabilities, including reasonable
          fees and costs, arising out of or in any way connected with your access to or use of Riby
          CoBanking
        </p>
        <p>6. Maintenance and Modifications to the Service</p>
        <p>
          6.1 The Service will be available 24 hours a day, seven days a week. However, due to
          hosting or mobile network faults, the Service may be unavailable. At the discovery of this
          fault occasioning disruption of the Service, we will use reasonable endeavours to inform
          you of such disruption.
        </p>
        <p>
          6.2 In order to always serve you better and give you the utmost experience of the Service,
          we may, at our sole discretion, periodically make modifications to the Service, conduct
          scheduled maintenance and where absolutely necessary, make complete overhaul of the
          Service for a new and better release. We will give you an advance notice of any such
          proposed maintenance, modification or overhauling of the Service.
        </p>
        <p>
          6.3 Likewise, based on your use of the Service, you may propose modifications or new
          features to the Service to us that we may, at our sole discretion, incorporate in future
          releases of the Service. However, such new releases or modifications may attract fees and
          you agree to pay us the agreed fees, if any, for modifications made to the Service.
        </p>
        <p>
          6.4 Where applicable, you agree to engage our partners for savings, loans and investment.
          This, however, will require our partners to onboard you on their platforms, and offer
          their services to you directly. However, we are not bound by any contract between you and
          our partners and do not give any warranty of profitability or any other warranty from such
          contract.
        </p>
        <p>7. Intellectual Property Rights</p>
        <p>
          7.1 The rights in the intellectual property of the Service belongs absolutely to the
          Company. You have no claim or right to such intellectual property either during your use
          of the Service of thereafter.
        </p>
        <p>
          7.2 You shall not, without prior written authorisation from us, access or use the Service
          with any tool, technology or means other than those specified by the Company;
        </p>
        <p>
          7.3 Based on 7.1 above, you do not have and no right is granted to you in any intellectual
          property of Riby in the Service, be it copyright, trademark, patent or any other
          proprietary right of RIBY or any third party or partner deployed in the Service. The
          components of Riby CoBanking such as logos, images, texts, page headers, sounds, custom
          graphics, and button icons are protected by copyright, trademark, and other applicable
          laws and may not be copied nor imitated either in whole or in part. Except with our
          express written consent, you shall not use, frame, or deploy framing techniques to enclose
          any RIBY’s logo, text, content image, trademark, or other proprietary information found on
          Riby CoBanking or our website without our express written consent.
        </p>
        <p>
          7.4 It is to be noted that whatever novel feature, modification, new release, product, app
          or service, that you suggest to us or request from us, and implemented in the Service
          shall remain the exclusive and sole intellectual property of the Company irrespective of
          the usage or success of such feature, modification, new release, product, app or service.
        </p>
        <p>
          7.5 You, for whatsoever purpose, agree to not or aid a third party to or attempt to
          reverse engineer, create, copy, modify, discover any source code, transfer, assign, sell,
          license, sublicense, use as security or grant permission to use any right in the Service
          or the software upon which the Service is built either in whole or in part.
        </p>
        <p>8. Data protection</p>

        <p>
          For individuals, cooperatives and savings groups, the collection, usage, storage and
          processing of personally identifiable information of your members are subject to the
          relevant and applicable data protection laws and regulations such as the Nigeria Data
          Protection Regulation (NDPR) 2019 or any amendment thereto. Our{" "}
          <a href="https://riby.ng/privacy">Privacy Notice</a> sets out in details what information
          we collect, how we collect and store such information, what we do with your information,
          the security measures we take in protecting your information and your attendant rights. As
          a Cooperative Admin, where you collect such personally identifiable information of your
          members, you agree to bear sole responsibility for compliance with applicable data
          protection laws in protecting such information.
        </p>
        <p>9. Assignment</p>
        <p>
          In any instance and at any time, we may assign or transfer our rights and obligations
          under this Terms and/or the SLA to any party without prior notice to you. You confirm and
          agree that the provisions set forth above are fair and reasonable and your agreement to be
          bound by them is not based on fraud, duress or undue influence exercised by us or any
          other person acting for us.
        </p>
        <p>10. Responsibilities</p>
        <p>10.1. It is your sole responsibility:</p>
        <p>
          10.1.1. To supply correct and accurate information during your registration for the
          Service.
        </p>
        <p>
          10.1.2. To promptly inform us of any error, mishap, or fault you may encounter while using
          the Service.
        </p>
        <p>
          10.1.3 To obtain all information fairly and only for the specific purpose it is required.
        </p>
        <p>10.1.4 Keep your information including your login details safe and secure.</p>
        <p>11. Notices</p>
        <p>
          11.1 All notices from us to you shall be served at the address provided by in the SLA
          and/or the address you supply while registering for the Service.
        </p>
        <p>
          11.2. All notices from you to us shall be served at our corporate address at Block G House
          4, Moore Road, Yaba, Lagos.
        </p>
        <p>
          11.3 Notices not sent to our physical address will be deemed to have been served; if sent
          to registered email, on receipt of a reply or in absence of a reply, 24 hours after
          sending; if sent by post, 48 hours after posting.
        </p>
        <p>12. Force Majeure</p>
        <p>
          Where our operations of the Service is prevented by fire, flood, harsh weather, lockouts,
          slowdown, outbreak of disease, pandemic, epidemic, industrial action, adverse legislation,
          governmental action or any other circumstances beyond our reasonable control (force
          majeure event), we shall not be liable for the inability to perform our obligations to you
          caused by such force majeure event but we will notify you of such circumstance within a
          reasonable time of being aware of such force majeure event.
        </p>
        <p>13. Termination </p>
        <p>
          Either Party shall be entitled to terminate the use of the Service with or without cause
          upon giving one (1) month written notice to the other Party.
        </p>
        <p>
          13.1 Either of the Parties may terminate the use of the Service with cause upon any
          material breach of this Terms of Use and/or the Terms and Conditions of the SLA by the
          other Party and such Party fails to remedy such breach within 30 (thirty) days of receipt
          of a written notice from the aggrieved Party specifying the nature of the breach and
          requiring its remedy.
        </p>
        <p>
          13.2 Riby may terminate the use of the Service with cause forthwith upon written notice
          and without any rectification period:
        </p>
        <p>
          13.2.1 If Riby discovers any marketing activity promoting our brand or products without
          our prior written consent;
        </p>
        <p>
          13.2.2 If the Service is used for a purpose other than that which it was agreed between
          the Parties to be used for.
        </p>
        <p>13.2.3 If any of the Parties ceases to be a going concern or ceases operation.</p>
        <p>
          13.2.4 For unethical conduct by You, but not limited to, offering or providing to any Riby
          employee any financial or other incentive for the purpose of obtaining customer leads or
          other information which is deemed to be beneficial to Riby;
        </p>
        <p>
          13.2.5 If the use of the Service by You is deemed to be for any illegal purpose or if you
          use the Service to commit any illegal and/or unlawful act as determined by a court of
          competent jurisdiction; or
        </p>
        <p>
          13.2.6 if any of Riby’s branding or communication material is used for any other purpose
          other than to solicit customers for Riby.
        </p>
        <p>13.3 Upon termination of the Service, each Party shall immediately:</p>
        <p>13.3.1 cease to present itself as being associated with the other Party;</p>
        <p>
          13.3.2 remove from display or access, and destroy or return to the other Party at its own
          cost, all papers, materials, data and any other promotional material or other property
          which refers to the other party or which contains the other party’s name or logo in its
          possession or held in connection with the performance of this Terms and/or the SLA; and
        </p>
        <p>
          13.3.3 remove from its website any links, contracts or other forms which relate to or
          mention the other Party, or which have been created by the other Party.
        </p>
        <p>
          13.4 Termination of the use of the Service and, where applicable, the SLA shall not affect
          any obligations of the respective Parties incurred or arising prior to such termination
          becoming effective and such obligation shall become due and payable forthwith on
          termination
        </p>
        <p>
          13.5 Riby CoBanking is designed for the use of cooperatives, associations and Savings
          groups only and upon completion of registration, such cooperatives, associations and
          savings group must not redesign or use the design for any technical development or
          operations. Such act will lead to suspension or termination of the Service and you may be
          subject to further legal actions after the suspension or termination.
        </p>
        <p>14. Entire Agreement</p>
        <p>
          You understand and agree that this Terms and where applicable, the SLA, constitute the
          entire agreement between you and us. This Terms and the relationship between You and Us
          will be governed by the laws of the Federal Republic of Nigeria. Our failure to exercise
          or enforce any right or provision of this Terms and/or the SLA will not constitute a
          waiver of such right or provision. If any provision of this Terms and the SLA are found by
          a court of competent jurisdiction to be invalid, the Parties nevertheless agree that the
          court should endeavor to give effect to the parties' intentions as reflected in the
          provision, and that the other provisions of this Terms and/or the SLA remain in full force
          and effect.
        </p>
        <p>
          YOU HEREBY AGREE THAT BY SIGNING UP AND USING RIBY COBANKING AND OUR OTHERS PRODUCTS AND
          SERVICES, YOU AGREE TO BE BOUND BY THE PROVISIONS OF THIS TERMS OF USE AND ALSO CONFIRM
          AND AGREE THAT THIS REPRESENTS THE ENTIRE UNDERSTANDING BETWEEN US AND YOU. ALL NEW
          RELEASES, NEW FEATURES AND MODIFICATIONS TO OUR PRODUCTS SHALL BE SUBJECT TO THIS SAME
          TERMS OF USE. YOU AGREE THAT YOU HAVE THE AUTHORITY TO AGREE TO THESE TERMS.
        </p>
      </div>
    </>
  );
};

export default Terms;
