import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import heroImg from "../../image/heroMock.png";
import { Button } from "../Button";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Wrapper>
        <TextContainer>
          <Fade bottom>
            <h1>
              Building Affordable and Accessible Digital Platform to Promote Collective Growth
            </h1>
          </Fade>

          <Fade bottom>
            <p>
              Riby is a digital and financial services company and is one of Nigeria’s foremost
              digital platform providers for cooperatives, savings Groups, associations, micro &
              small-scale enterprises, and trade groups
            </p>
            <Button title={"Contact Us"} onClick={() => navigate("/contact")} />
          </Fade>
        </TextContainer>
        <Fade right>
          <ImgContainer src={heroImg} alt="riby-header-image" />
        </Fade>
      </Wrapper>
    </Container>
  );
};
export default Hero;

const ImgContainer = styled.img`
  position: relative;
  // top: 97px;
  z-index: 1;
  left: 20px;
  // width: 100%;
  @media (max-width: 1220px) {
    // display: none;
    left: 0;
    width: 70%;
  }
  @media (max-width: 768px) {
    // display: none;
    left: 0;
    width: 100%;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  color: #ffff;
  position: relative;
  left: 60px;
  @media (max-width: 1220px) {
    width: 100%;
    padding: 20px;
    text-align: left;
    left: 0;
    margin: 0 2rem;
  }
  @media (max-width: 800px) {
    width: 100%;
    padding: 0.7rem;
    text-align: left;
    left: 0;
    margin: 0;
  }

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    // line-height: 88px;
    color: #0f0a38;
    @media (max-width: 768px) {
      width: 100%;
      text-align: left;
      font-size: 2.5rem;
    }

    @media (max-width: 400px) {
      width: 100%;
      text-align: left;
      font-size: 1.6rem;
    }
  }

  p {
    color: #0f0a38;
    width: 100%;
    margin: 0;
    font-size: 1.25rem;
    @media (max-width: 768px) {
      width: 100%;
      text-align: left;
      font-size: 1.1rem;
    }
  }
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 70vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  // flex-wrap: wrap-reverse;
  color: #0f0738;
  padding: 1.25rem;
  overflow: hidden;
  @media (max-width: 800px) {
    min-height: 50vh;
    display: flex;
    flex-wrap: wrap-reverse;
  }
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 70vh;
  background: ##f8feff;

  @media (max-width: 800px) {
    min-height: 50vh;
    margin-top: 0;
  }
`;
