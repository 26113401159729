export const data = [
  {
    question: "How do I become an agent",
    number: "1",
    answer: "Please visit the playstore on your device, download and install the Riby CoAgent app.",
  },
  {
    question: "What do I need to become an agent",
    number: "2",
    answer:
      "You need to belong to a cooperative and if you do not belong to any, you can opt for Riby cooperative which is available for this purpose.",
  },
  {
    number: "3",
    question: "What is the app used for?",
    answer:
      "It is used for the collection of cooperative member's contributions, thrift collection, loan booking and repayment with their cooperatives, bills payment, airtime purchase and other financial services features.",
  },
  {
    number: "4",
    question: "How do I download the Riby CoAgent app?",
    answer: "Look  for Riby CoBanking on your playstore to download and signup",
  },
  {
    number: "5",
    question: " What should I do if I encounter any issues with the app?",
    answer: "Please contact us via support line: 01-2914247 or send a mail to support@riby.ng",
  },
];
