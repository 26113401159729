import React, { useEffect } from "react";
// import styled from "styled-components";
import SupportWidget from "../../../component/SupportWidget";
import googleStoreImg from "../../../image/google.png";
import Navbar from "../../../component/Navbar";
import posAgent from "../../../image/femiAgent.png";
import { ButtonContainer, HeroText, Containers } from "../CobankingApp/styles";
import Fade from "react-reveal/Fade";
import Footer from "../../../component/Footer";
import img from "../../../image/landingCoImg.png";
import mock2 from "../../../image/agentMock.png";
import styled from "styled-components";
import { ChannelCard, ChannelContent, Flex, FlexContainer } from "../CobankingApp";
import { ProductCard } from "../../Business/CardContainer";
import { TextHead, TextDesc } from "../../Business/AgentBusiness";
import mesBox from "../../../image/mesBox.png";
import phoneImg from "../../../image/phoneImg.png";
import { TextHeader } from "../../Business/Financial Inclusion";
import { Button } from "../../../component/Button";
import { AgentReg, ChannelData } from "../../../helper/AgentRegData";
import FaqAccordion from "./FaqAccordion";
import { data } from "./data";
import { Helmet } from "react-helmet";
import environment from "../../../config/environment";

const AgentBankApp = () => {
  const { agentAppUrl } = environment;

  useEffect(() => {
    document.title = "Agency Banking - Download Now";
  }, []);
  return (
    <>
      <Navbar img="/Logo.png" />
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Riby Agent Banking - Riby Agent banking provides a solution that aids collection and
          management for cooperative and thrift collection.
        </title>
        <meta
          name="description"
          content="Riby Cobanking is winning the race to becoming Nigeria's best cooperative software
          solution. You can grow your cooperative members, carryout thrift collection activities, as
          well as managing loan disbursement and repayment"
        />
      </Helmet>
      <Containers>
        <Hero>
          <Fade left>
            <HeroText>
              <h3>Our agency banking mobile app for all financial services transactions</h3>

              <p>
                A specially designed agent banking mobile app that has the unique feature to
                integrate with different payment channels on a POS to perform financial operations
                such as, cash withdrawals (Cash Out), cash deposits (Cash In), bill payments and
                other related financial services.
              </p>
              <a href="https://agency.riby.ng/login" rel="noreferrer" target="_blank">
                <Button title="Get Started" />
              </a>
            </HeroText>
          </Fade>
          <Fade right>
            <ImageContainer>
              <HeroImg src={img} alt="hero img for agnetBank" />
            </ImageContainer>
          </Fade>
        </Hero>
        <Flex>
          <FlexContainer>
            <Fade left>
              {ChannelData.map((data, index) => (
                <ChannelCard key={index} bs={data.bs}>
                  <img src={data.img} alt="" />
                  <h3>{data.textHeader}</h3>
                  <p>{data.description}</p>
                </ChannelCard>
              ))}
            </Fade>
          </FlexContainer>
          <ChannelContent>
            <Fade right>
              <h2>Four simple Requirements to become An Agent</h2>
              <p>
                Did you know that you can increase your net worth by earning a monthly extra income
                as a Riby agent? Just meet this four requirements.
              </p>
            </Fade>
          </ChannelContent>
        </Flex>
        <ProductCard
          ta
          fd
          img={posAgent}
          textHead={
            <TextHeader>
              <TextHead>Become A Riby Agent</TextHead>
            </TextHeader>
          }
          textDesc={
            <TextHeader>
              <TextDesc> To become a Riby Agent reach us on any the channels below</TextDesc>
              <TextDesc style={{ display: "flex", alignItems: "center", margin: "10px 0" }}>
                {" "}
                <img src={mesBox} alt="" style={{ marginRight: "5px" }} />{" "}
                <Underline href="mailto:agentsupport@riby.ng">agentsupport@riby.ng</Underline>
              </TextDesc>
              <TextDesc style={{ display: "flex", alignItems: "center", margin: "10px 0" }}>
                {" "}
                <img src={phoneImg} alt="" style={{ marginRight: "5px" }} />{" "}
                <Underline href="tel:+234 704 716 6345">+234 704 716 6345</Underline>
              </TextDesc>
            </TextHeader>
          }
        />
        {/* <Fade bottom> */}
        <RegWrapper>
          <Fade left>
            <RegContent>
              <h2>How It Works</h2>
              <p>
                Every successful individual knows that his or her achievement depends on a community
                of persons working together.
              </p>
              <a href={agentAppUrl} rel="noreferrer" target="_blank">
                <Button title="Get Started" />
              </a>
            </RegContent>
          </Fade>
          <Fade right>
            <RegOption>
              {AgentReg?.map((data, index) => (
                <Card key={index}>
                  <h1>{data.number}</h1>
                  <h4>{data.title}</h4>
                  <ul>
                    {data?.steps?.map((data1, index) => (
                      <li key={index}>{data1}</li>
                    ))}
                  </ul>
                </Card>
              ))}
            </RegOption>
          </Fade>
        </RegWrapper>
        {/* </Fade> */}
        <Hero>
          <Fade left>
            <HeroText>
              <h3>Our agent banking mobile app for financial services And transactions</h3>

              <p>
                Download our on-the-go agent banking mobile app for all financial services
                transactions. It is specially designed with unique feature to integrate on a POS to
                perform financial operations such as, cash withdrawals (Cash Out), cash deposits
                (Cash In), bill payments and other related financial services.
              </p>

              <ButtonContainer>
                <a href="https://play.google.com/store/apps/details?id=ng.riby.agent">
                  <img src={googleStoreImg} alt="" />
                </a>
                {/* <a href="#">
                  <img src={playStoreImg} alt="" />
                </a> */}
              </ButtonContainer>
            </HeroText>
          </Fade>
          <Fade right>
            <ImageContainer2>
              <HeroImg2 src={mock2} alt="hero img for agentBank" />
            </ImageContainer2>
          </Fade>
        </Hero>
        <Fade bottom>
          <JoinContainer>
            <JoinWrapper>
              <h1>Want to make more money working as a Riby Agent?</h1>
              <a href="https://agency.riby.ng/login" rel="noreferrer" target="_blank">
                <Button title="Get Started" />
              </a>
            </JoinWrapper>
          </JoinContainer>
        </Fade>
        <Faq>
          <h3>Frequently Asked Questions</h3>
          {data.map((data, i) => (
            <FaqAccordion key={i} data={data} />
          ))}
        </Faq>
      </Containers>

      {/* <SupportWidget /> */}
      <Footer />
    </>
  );
};

export default AgentBankApp;

const Faq = styled.div`
  width: 100%;
  // min-height: 70vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const HeroImg2 = styled.img`
  width: 400px;
  margin-left: 50px;
  position: absolute;
  top: 120px;
  left: 30px;
  // clip: rect(25px, 100px, 100px, 25px);
  @media (max-width: 768px) {
    margin-left: 0;
    top: 80px;
    left: 50px;
    width: 300px;
  }
  // @media (max-width: 450px) {
  //   margin-left: 0;
  //   width: 200px;
  // }
`;

export const ImageContainer2 = styled.div`
  position: relative;
  width: 570px;
  height: 570px;
  border-radius: 50% 50% 50% 50%;
  margin-left: 30px;
  background: transparent;
  overflow: hidden;
  @media (max-width: 768px) {
    margin-left: 0;
    width: 400px;
    height: 400px;
  }
  @media (max-width: 400px) {
    margin-left: 0;
    width: 300px;
    height: 300px;
  }
`;

const JoinWrapper = styled.div`
  width: 80%;
  min-height: 50vh;
  height: 100%;
  background: linear-gradient(278.49deg, #370d92 27.83%, #af66e8 101.88%);
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  flex-wrap: wrap;
  h1 {
    width: 648px;
    font-family: Outfit;
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    // text-align: left;
    color: #fff;

    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
    }
  }
`;
const JoinContainer = styled.div`
  width: 100%;
  min-height: 70vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Card = styled.div`
  padding: 35px;
  width: 250px;
  height: 240px;
  background: #0f0a38;
  box-shadow: 1px 3px 10px rgba(13, 34, 61, 0.1);
  border-radius: 10px;
  color: #fff;
  margin: 10px;
  transition: all 350ms;
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    margin: 0;
    margin-left: 15px;
    padding: 0;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  h1 {
    color: #fff;
    font-family: Outfit;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
  }
  h4 {
    color: #fff;
    font-family: Outfit;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  @media (max-width: 768px) {
    width: 230px;
    height: 220px;
  }

  &:hover {
    transition: all 350ms;
    background: linear-gradient(285deg, #f00b51, #e20082);
  }
`;
const RegContent = styled.div`
  width: 450px;
  h2 {
    font-family: Outfit;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
  }
  p {
    font-family: Outfit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #fff;
    text-align: left;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const RegOption = styled.div`
  width: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
const RegWrapper = styled.div`
  width: 100%;
  min-height: 80vh;
  height: 100%;
  background: rgba(13, 34, 61, 0.5);
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap-reverse;
  padding: 20px;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 570px;
  height: 570px;
  border-radius: 50% 50% 50% 50%;
  margin-left: 30px;
  background: #83909e;
  // overflow: hidden;
  @media (max-width: 768px) {
    margin-left: 0;
    width: 400px;
    height: 400px;
    // width: 100%;
  }
  @media (max-width: 400px) {
    margin-left: 0;
    width: 300px;
    height: 300px;
  }
`;
export const Underline = styled.a`
  color: #0d223d;

  &:hover {
    text-decoration: underline;
  }
`;

export const HeroImg = styled.img`
  width: 700px;
  margin-left: 50px;
  position: absolute;
  top: 50px;
  left: -120px;
  // clip: rect(25px, 100px, 100px, 25px);
  @media (max-width: 768px) {
    margin-left: 0;
    top: 30px;
    left: -60px;
    width: 500px;
  }
  @media (max-width: 450px) {
    margin-left: 0;
    width: 350px;
    top: 30px;
    left: -30px;
  }
  @media (width: 414px) {
    margin-left: 0;
    width: 400px;
    top: 50px;
    left: -0px;
  }
`;

export const Hero = styled.div`
  width: 100%;
  height: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap-reverse;

  @media (max-width: 768px) {
    justify-content: space-around;
    margin-top: 0px;
    min-height: 100vh;
  }
`;
