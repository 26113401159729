import { useState } from "react";
import { dbstore } from "../firebase";
import { doc, getDocFromCache } from "firebase/firestore";

export const useFetchSingle = () => {
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const [singleData, setsingleData] = useState(null);
  const fetchSingle = async (id) => {
    const docRef = doc(dbstore, "jobs", id);
    setPending(true);
    try {
      const doc = await getDocFromCache(docRef);
      //   console.log("*****", doc.data());
      setsingleData(doc.data());
      setPending(false);
    } catch (error) {
      console.log(error);
      setPending(false);
    }
  };
  return { pending, error, singleData, fetchSingle };
};
