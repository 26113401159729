import { Container, Wrapper, TopFlex, TitleHead } from "./styles";
import React from "react";
import Fade from "react-reveal/Fade";
import BusinessComponent from "./BusinessCarousel";

const Business = () => {
  return (
    <Container>
      <Wrapper>
        <Fade bottom>
          <TopFlex>
            <TitleHead>Our Gallery</TitleHead>
          </TopFlex>
        </Fade>
        <BusinessComponent />
      </Wrapper>
    </Container>
  );
};
export default Business;
