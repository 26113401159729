import styled from "styled-components";
import "../index.css";
// import { useEffect } from "react";
import Hero from "../component/Home/Hero";
import Purpose from "../component/Home/Purpose";
import Products from "../component/Home/Products";
import Business from "../component/Home/Business";
import Sponsors from "../component/Home/Sponsors";
import Testimonies from "../component/Home/Testimonies";
import Footer from "../component/Footer";
import SupportWidget from "../component/SupportWidget";
import Navbar from "../component/Navbar";
import { Helmet } from "react-helmet";

const Home = () => {
  // useEffect(() => {
  //   document.title =
  //     "Home -A digital platform providers for cooperatives, savings Groups, associations, micro & small-scale enterprises, and trade groups";
  // }, []);

  return (
    <>
      <Navbar img="/Logo.png" />
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Riby - Welcome to a digital platform providers for cooperatves, saving groups,
          associations, micro & small-scale enterprises, and trade groups{" "}
        </title>
        <description>
          Riby is a digital and financial services company and is one of Nigeria’s foremost digital
          platform providers for cooperatives, savings Groups, associations, micro & small-scale
          enterprises, and trade groups
        </description>
      </Helmet>
      <Container>
        <Hero />
        <Purpose />
        <Products />
        <Business />
        <Sponsors />
        <Testimonies />
      </Container>

      <SupportWidget />
      <Footer />
    </>
  );
};

export default Home;

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;
