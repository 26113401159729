import regImg from "../image/bizRegImg.png";
import utilsImg from "../image/utilityImg.png";
import idCard from "../image/cardImg.png";
import userImg from "../image/userImg.png";
export const AgentReg = [
  {
    title: "Registration",
    number: "01",
    steps: [
      "Register via link",
      "Submit copy of ID card",
      "Submit utility bill and other required document(s)",
    ],
  },
  {
    title: "Verification and activation",
    number: "02",
    steps: ["Verification of details", "Agent activation"],
  },
  {
    title: "Funding",
    number: "03",
    steps: ["Have a minimum of #5,000 in a valid account", "Confirmation of funds"],
  },
  {
    title: "Training and branding",
    number: "04",
    steps: ["Training", "Get starter pack", "Market support", "Start earning"],
  },
];

export const ChannelData = [
  {
    img: userImg,
    textHeader: "Passport Photograph",
    description: "One passport photograph",
    bs: true,
  },
  {
    img: idCard,
    textHeader: "Valid Id",
    description: "NIMC, Driver’s license or International passport",
    bs: true,
  },
  {
    img: utilsImg,
    textHeader: "Utility Bill",
    description: "Electricity, waste bill or water bill",
    bs: true,
  },
  {
    img: regImg,
    textHeader: "Business Registration",
    description: "Business registered documents (If CAC required)",
    bs: true,
  },
];
