import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Navbar from "../../../component/Navbar";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import environment from "../../../config/environment";
import {
  ProductAction,
  ButtonContainer,
  HeroText,
  Containers,
  Content,
  TextContent,
  SavemoreContainer,
  SavemoreText,
  Dashboard,
  SaveMoreWraper,
} from "./styles";
import SupportWidget from "../../../component/SupportWidget";
import { Button } from "../../../component/Button";
import mockimg from "../../../image/cobankheroimg.png";
import googlelight from "../../../image/googlelight.png";
import ussd from "../../../image/ussd.png";
import dashboard from "../../../image/codashboards.png";
import cophone from "../../../image/cophone.png";
import chIcon1 from "../../../image/desktop.png";
import chIcon2 from "../../../image/iphone.png";
import chIcon3 from "../../../image/hash.png";
import chIcon4 from "../../../image/statue.png";
import img from "../../../image/faqImg/faqmockup.png";
import Footer from "../../../component/Footer";
import Fade from "react-reveal/Fade";
import { coData } from "../../../helper/cobankData";

const CobankingApp = () => {
  // useEffect(() => {
  //   document.title = "Download Co-banking App";
  // }, []);
  const { rcbAppUrl } = environment;

  const navigate = useNavigate();

  const ChannelData = [
    {
      img: chIcon1,
      textHeader: "Web App",
      description: "Available on riby.me/cobanking",
      bs: true,
    },
    {
      img: chIcon2,
      textHeader: "Mobile App",
      description: "Get it on Google Play, Coming soon to Apple store",
      bs: true,
    },
    {
      img: chIcon3,
      textHeader: "USSD",
      description: "Dial *347*66#",
      bs: true,
    },
    {
      img: chIcon4,
      textHeader: "Agent Network",
      description: "Go to any of our Agent Points across the country",
      bs: true,
    },
  ];

  return (
    <>
      <Navbar img="/Logo.png" />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Riby CoBanking</title>
        <meta
          name="description"
          content="Riby Cobanking is winning the race to becoming Nigeria's best cooperative software
          solution. You can grow your cooperative members, carryout thrift collection activities, as
          well as managing loan disbursement and repayment. Get started"
        />
      </Helmet>
      <Containers>
        <Hero>
          <Fade left>
            <HeroText>
              <h3>A digital cooperative management platform that suits your needs</h3>
              <p>
                Riby CoBanking is a complete financial management tool for cooperatives, trade
                groups, associations and savings groups.
              </p>
              <p>
                Riby Cobanking is winning the race to becoming Nigeria's best cooperative software
                solution. You can grow your cooperative members, carryout thrift collection
                activities, as well as managing loan disbursement and repayment. Get started,
              </p>
              <div style={{ display: "flex", gap: "1.5rem" }}>
                <Button title="Get Started" onClick={() => navigate("/registercooperative")} />
                <a href={rcbAppUrl} target="_blank" rel="noreferrer">
                  <LoginBtn>Login</LoginBtn>
                </a>
              </div>
            </HeroText>
          </Fade>
          <Fade right>
            <ImageContainer>
              <HeroImg src={mockimg} />
            </ImageContainer>
          </Fade>
        </Hero>

        <ProductContainer>
          <CardsHeader>A Collection Of Advanced Features</CardsHeader>
          <ProductInfo>
            {coData.map((data, index) => (
              <Fade bottom>
                <ProductCard key={index}>
                  <img src={data.img} alt="" />
                  <h5>{data.title}</h5>
                  <p>{data.descrip}</p>
                </ProductCard>
              </Fade>
            ))}
          </ProductInfo>
        </ProductContainer>
        <SavemoreContainer>
          <SaveMoreWraper>
            <SavemoreText>
              <h2>More time saving, less time wasting</h2>
              <p>
                Set up a cooperative or group savings account within minutes. Start saving towards a
                financial goal and invest with your friends, family, colleagues and group of choice.
              </p>
              <Button title="Get Started" onClick={() => navigate("/registercooperative")} />
            </SavemoreText>

            <Dashboard src={dashboard} alt="" />
          </SaveMoreWraper>
        </SavemoreContainer>
        <Flex>
          <ChannelContent>
            <h2>Available across several Channels</h2>
            <p>Riby CoBanking can be accessed across several platforms for seamless saving.</p>
          </ChannelContent>
          <FlexContainer>
            {ChannelData.map((data, index) => (
              <ChannelCard key={index} bs={data.bs}>
                <img src={data.img} alt="" />
                <h3>{data.textHeader}</h3>
                <p>{data.description}</p>
              </ChannelCard>
            ))}
          </FlexContainer>
        </Flex>
        <Container>
          <FaqContainer>
            <div>
              <h1>FAQ</h1>
              <p>
                Get answers, solutions and explore everything you need to know about Riby CoBanking
                all at your fingertips. Click the{" "}
                <span style={{ color: "#EB4967" }}> read more</span> button to find answers to your
                questions
              </p>
              <Button title="Read More" onClick={() => navigate("/product/CobankingApp/faq")} />
            </div>
            <img src={img} alt="faqmockup" />
          </FaqContainer>
        </Container>
        <ProductAction>
          <Content>
            <TextContent>
              <h2>Members Contributing With Ease And On The Go</h2>
              <p>
                Our app allows members of your group savings or cooperative to promptly save and
                make contributions as at when due, easily and on the go
              </p>
              <ButtonContainer>
                <a
                  href="https://play.google.com/store/apps/details?id=ng.riby.rcb"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={googlelight} alt="" />
                </a>
                <a href="#" target="_blank" rel="noreferrer">
                  <img src={ussd} alt="" />
                </a>
              </ButtonContainer>
            </TextContent>
            <div>
              <img src={cophone} alt="" width={250} />
            </div>
          </Content>
        </ProductAction>
      </Containers>
      <SupportWidget />
      <Footer />
    </>
  );
};

export default CobankingApp;

const FaqContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    margin-top: 1.5rem;
  }
  img {
    width: 600px;
    @media (max-width: 768px) {
      width: 100%;
      display: none;
    }
  }
  h1 {
    font-size: 4rem;
    color: #0f0738;
    margin-bottom: 0.2rem;
  }
  p {
    // width: 50%;
    color: #0f0738;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const LoginBtn = styled.div`
  width: 150px;
  height: 50px;
  border: 1px solid #e20082;
  margin: 1px 4px;
  font-weight: 500;
  // background: linear-gradient(285deg, #f00b51, #e20082);
  border-radius: 10px;
  color: #f00b51;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const ImageContainer = styled.div`
  position: relative;
  width: 570px;
  height: 570px;
  border-radius: 50% 50% 50% 50%;
  margin-left: 30px;
  background: #83909e;
  // overflow: hidden;
  @media (max-width: 768px) {
    margin-left: 0;
    width: 400px;
    height: 400px;
    // width: 100%;
  }
  @media (max-width: 400px) {
    margin-left: 0;
    width: 300px;
    height: 300px;
  }
`;

export const HeroImg = styled.img`
  width: 550px;
  margin-left: 50px;
  position: absolute;
  top: 170px;
  left: -20px;
  // clip: rect(25px, 100px, 100px, 25px);
  @media (max-width: 768px) {
    margin-left: 0;
    top: 130px;
    left: 20px;
    width: 400px;
    // width: 100%;
  }
  @media (max-width: 450px) {
    margin-left: 0;
    width: 300px;
    top: 70px;
    left: -10px;
  }
  @media (width: 414px) {
    margin-left: 0;
    width: 400px;
    top: 80px;
    left: -0px;
  }
`;

export const Hero = styled.div`
  width: 100%;
  height: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap-reverse;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    justify-content: center;

    margin-bottom: 100px;
    min-height: 100vh;
  }

  @media (max-width: 908px) {
    justify-content: center;

    margin-bottom: 70px;
    min-height: 100vh;
  }
`;

const CardsHeader = styled.h2`
  text-align: center;
  marginbottom: 1.6em;
  margintop: 2em;
  color: #0f0738;
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const ProductContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProductInfo = styled.div`
  width: 100%;
  height: 100%;
  min-height: 70vh;
  // background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const ProductCard = styled.div`
  width: 400px;
  min-height: 300px;
  height: 100%;
  padding: 12px;
  background: #edf2f3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin: 10px;
  transition: all 350ms;
  border: 1px solid #edf2f3;
  transform: scale(1);
  border-radius: 7px;
  img {
    width: 40px;
  }

  h5 {
    margin: 0;
    padding: 7px;
    font-size: 20px;
    text-align: center;
    color: #0f0738;
  }
  p {
    margin: 0;
    padding: 0;
    text-align: center;
    color: #0f0738;
  }

  &:hover {
    transform: scale(1.01);
    cursor: pointer;
    border: 0.5px solid #0d223d;
  }
  @media (max-width: 600px) {
    width: 350px;
  }
`;

export const FlexContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Flex = styled.div`
  width: 100%;
  height: 100%;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  // margin-top: 70px;
`;
const FlexPricing = styled.div`
  width: 100%;
  height: 100%;
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h3 {
    color: #0f0738;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 1.3rem;
    }
  }
  p {
    color: #0f0738;
    text-align: center;
    width: 500px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const ChannelContent = styled.div`
  width: 40%;
  color: #0f0738;
  padding: 20px;
  h2 {
    color: #0f0738;
    @media (max-width: 768px) {
      font-size: 1.3rem;
    }
  }
  p {
    font-family: Outfit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

export const ChannelCard = styled.div`
  width: 264px;
  height: 200px;
  padding: 10px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: ${({ bs }) => (bs ? "1px 3px 10px rgba(13, 34, 61, 0.1)" : "")};
  text-align: center;
  border-radius: 10px;
  font-family: outline;
  color: #0f0738;
  margin: 7px;
   {
    /* margin: 10px; */
  }
  transition: all 350ms;
  &:hover {
    cursor: pointer;
    border: 1px solid #d80c1b;
    box-shadow: 0px 4px 4px rgba(15, 7, 56, 0.16);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }

  img {
    margin-bottom: 10px;
  }

  h3 {
    font-weight: 500;
    margin-top: 4px;
    padding: 0;
    margin: 0;
    // line-height: 24px;
    font-size: 18px;
    font-family: outline;
    color: #0f0738;
  }
  p {
    font-size: 14px;
    // line-height: 24px;
    font-family: outline;
    color: #0f0738;
  }
`;
