// import react from "react";
import { Container, Wrapper } from "./styles";
import styled, { keyframes } from "styled-components";
import echo from "../../image/sponsor/echo.png";
import efina from "../../image/sponsor/efina.png";
import flutterwave from "../../image/sponsor/flutterwave.png";
import greencapital from "../../image/sponsor/greencapital.png";
import ilo from "../../image/sponsor/ilo.png";
import interswitch from "../../image/sponsor/interswitch.png";
import loftyheight from "../../image/sponsor/loftyheight.png";
import lux from "../../image/sponsor/lux.png";
import mercycorps from "../../image/sponsor/mercycorps.png";
import microtraction from "../../image/sponsor/microtraction.png";
import nepc from "../../image/sponsor/nepc.png";
import norishing from "../../image/sponsor/norishing.png";
import passionincubator from "../../image/sponsor/passionincubator.png";
import providus from "../../image/sponsor/providus.png";
import sterling from "../../image/sponsor/sterling.png";
import verve from "../../image/sponsor/verve.png";
import vfd from "../../image/sponsor/vfd.png";
import growthcapital from "../../image/sponsor/growthcapital.png";
import boa from "../../image/sponsor/boa.png";
import { TopFlex, TitleHead } from "./styles";

const Sponsors = () => {
  const img1 = [
    norishing,
    echo,
    efina,
    boa,
    loftyheight,
    growthcapital,
    ilo,
    flutterwave,
    greencapital,
    mercycorps,
    passionincubator,
    nepc,
    interswitch,

    providus,
    vfd,
    sterling,
    microtraction,
    verve,
  ];

  return (
    <Container>
      <Wrapper>
        <TopFlex>
          <TitleHead>Partners</TitleHead>
        </TopFlex>
        <Container2>
          <Img src={greencapital} alt="" />
          <img src={flutterwave} alt="" />
          <img src={ilo} alt="" />
          <img src={growthcapital} alt="" />
          <img src={loftyheight} alt="" /> <img src={verve} alt="" />
          <img src={boa} alt="" />
          <img src={echo} alt="" />
          <img src={norishing} alt="" />
          <img src={efina} alt="" />
          <img src={verve} alt="" />
          <img src={microtraction} alt="" />
          <img src={sterling} alt="" />
          <img src={vfd} alt="" />
          <img src={providus} alt="" />
          {/* <img src={interswitch} alt="" /> */}
          <img src={nepc} alt="" />
          {/* <img src={passionincubator} alt="" /> */}
          <img src={mercycorps} alt="" />
          <img src={flutterwave} alt="" />
          <img src={ilo} alt="" />
          <img src={growthcapital} alt="" />
          <img src={loftyheight} alt="" /> <img src={verve} alt="" />
          <img src={boa} alt="" />
          <img src={echo} alt="" />
        </Container2>

        <>
          <Mobile>
            {img1.map((data, index) => (
              <img src={data} key={index} alt={data} width={50} style={{ margin: "5px" }} />
            ))}
          </Mobile>
        </>
      </Wrapper>
    </Container>
  );
};
export default Sponsors;

const bannermove = keyframes`
 0% {
    margin-left: 80px;
 }
 100% {
    margin-left: -1625px;
 }
`;

const Img = styled.img``;
const Container2 = styled.div`
  width: 5000px;
  overflow: hidden;
  // background: red;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${bannermove} 10s linear infinite;

  img {
    display: inline-block;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  @media (max-width: 1200px) {
    display: none;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const Mobile = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  display: none;
  @media (max-width: 1200px) {
    display: flex;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;
