import React from "react";
import { Link } from "react-router-dom";
// import "./Dropdown.css";
import styled from "styled-components";
import { ProductMenu } from "../MenuItems";

export const Dropdowns = () => {
  const NotClickable = (e) => {
    e.preventDefault();
  };

  return (
    <Container>
      <Wrapper>
        {ProductMenu?.map((product, i) => (
          <Link to={product.link} key={i} onClick={!product.link && NotClickable}>
            <Content>
              <Logo src={product.img} />
              <NavContainer>
                <h6>{product.productName}</h6>
                <p>{product.descrip}</p>
              </NavContainer>
              {!product.link && <BtnAlert>coming soon</BtnAlert>}
            </Content>
          </Link>
        ))}
      </Wrapper>
    </Container>
  );
};

const BtnAlert = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 17px;
  display: flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  background: rgba(216, 12, 27, 0.2);
  border-radius: 5px;
  color: #d80c1b;
`;
const Content = styled.div`
  width: 300px;
  display: flex;
  margin: 10px;
  position: relative;
  color: red;
  transition: all 350ms;

  &:hover {
    color: #0f0738;
    margin-left: 16px;
  }
`;
const Logo = styled.img`
  //   width: 50px;
  margin-right: 7px;
`;
const NavContainer = styled.div`
  position: relative;
  p {
    font-size: 12px;
    margin: 0;
    line-height: 1.1;
    color: #6b778c;
  }
  h6 {
    font-size: 15px;
    margin: 0;
    font-weight: 500;
    color: #6b778c;
  }
`;
const Container = styled.div`
  width: 350px;
  position: absolute;
  top: 34px;
  left: -60px;
  padding: 10px;
  z-index: 1;
  background: #f8feff;
  box-shadow: 0px 0px 6px 2px rgba(15, 7, 56, 0.1);
  border-radius: 10px;
  transition: all 350ms;
  ::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid #0f0738;
    position: absolute;
    z-index: 1;
    top: -22px;
    left: 32%;
    margin-left: -3px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 30vh;
  display: flex;
  // justify-content: left;
  // align-items: left;
  flex-direction: column;
  // flex-wrap: wrap;
`;
