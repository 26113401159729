import { useState } from "react";
import { onSnapshot, collection, getDocs } from "firebase/firestore";
import { dbstore } from "../firebase";

export const useFetchJob = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const fetchJob = async () => {
    setLoading(true);
    try {
      onSnapshot(collection(dbstore, "jobs"), (snapshot) => {
        const data = snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
        // console.log("£££££", data);
        setData(data);
        //   dispatch(allClient(data));
        setLoading(false);
      });
      
    } catch (error) {
      setLoading(false);
      setError("An unable to fetch client");
      console.log(error.message);
    }
  };

  return { error, loading, data, fetchJob };
};
