import "./Career-Section1.css";
import Fade from "react-reveal/Fade";

const Section1 = () => {
  return (
    <>
      <div className="career-container">
        <div className="career-section">
          <div className="career-hero">
            <Fade top>
              <h1>Career</h1>
            </Fade>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section1;
