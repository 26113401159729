import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { FaAngleDown } from "react-icons/fa";
import { Dropdowns } from "../Dropdown";
import { BusinessDropdown } from "../Dropdown/BusinessDropdown";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import MobileNav from "./MobileNav";

const Navbar = ({ bg, cl, img, pp, hc }) => {
  const [toggle, setToggle] = useState(false);
  const [navIcon, setNavIcon] = useState(false);
  const [businessNav, setBusinessNav] = useState(false);
  const outSideNav = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (outSideNav.current && !outSideNav.current.contains(event.target)) {
        // alert("you clicked outside");
        setToggle(false);
        setBusinessNav(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [outSideNav]);

  const handleToggleNav = () => {
    setNavIcon(!navIcon);
  };

  const handleBusinesNav = () => {
    setBusinessNav(!businessNav);
    setToggle(false);
  };

  const handleToggle = () => {
    setToggle(!toggle);
    setBusinessNav(false);
  };

  return (
    <Container ref={outSideNav} bg={bg} pp={pp}>
      <Wrapper>
        <Link to="/">
          <Logo src={img} />
        </Link>

        <Hamburger onClick={handleToggleNav} hc={hc}>
          <GiHamburgerMenu style={{ fontSize: "30px", cursor: "pointer" }} />
        </Hamburger>
        {navIcon && <MobileNav toggleNav={handleToggleNav} />}
        <Navigation cl={cl}>
          <Nav
            onClick={handleToggle}
            // onMouseEnter={() => setToggle(true)}
            // onMouseLeave={() => setToggle(false)}
          >
            Product
            <FaAngleDown />
            {toggle && <Dropdowns />}
          </Nav>

          <Nav
            onClick={handleBusinesNav}
            // onMouseEnter={() => setBusinessNav(true)}
            // onMouseLeave={() => setBusinessNav(false)}
          >
            Business
            <FaAngleDown />
            {businessNav && <BusinessDropdown />}
          </Nav>
          <Link to="/about">
            <Nav>About Us</Nav>
          </Link>
          <Nav>
            {" "}
            <a href="https://medium.com/riby" target="_blank" rel="noreferrer">
              Blog
            </a>
          </Nav>
          <Link to="/contact">
            <Nav>Contact</Nav>
          </Link>
        </Navigation>
      </Wrapper>
    </Container>
  );
};
export default Navbar;

const Hamburger = styled.div`
  display: none;
  @media (max-width: 875px) {
    display: flex;
    color: ${({ hc }) => (hc ? "#fff" : "#0f0738")};
    padding: 3px 12px;
  }
`;
const Nav = styled.div`
  cursor: pointer;
  font-size: 18px;
  margin: 0 20px;
  font-family: Outfit;
  position: relative;
  transition: all 350ms;
  // color: ${({ cl }) => (cl ? "#fff" : "#0f0738")};
  &:hover {
    color: #6b778c;
  }
  a {
    color: #0f0738;

    &:hover {
      color: #0f0738;
    }
  }
`;
const Navigation = styled.div`
  display: flex;
  justify-content: center;
  alignitems: center;
  padding: 3px 100px;
  color: ${({ cl }) => (cl ? "#fff" : "#0f0738")};

  @media (max-width: 875px) {
    display: none;
    padding: 0;
  }
  @media (min-width: 1150px) {
   3px 20px;
  }
  a {
    color: ${({ cl }) => (cl ? "#fff" : "#0f0738")};
  }
`;
const Logo = styled.img`
  padding: 3px 100px;
  @media (max-width: 768px) {
    padding: 3px 12px;
  }

  @media (max-width: 1150px) {
    padding: 3px 20px;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  height: 70px;
  background: ${({ bg }) => bg};
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
const Container = styled.div`
  width: 100%;
  height: 70px;
  background: ${({ bg }) => (bg ? "#0f0738" : "##F8FEFF")};
  // background: #0f0738;
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${({ pp }) => (pp ? "fixed" : "relative")};
  top: 0;
  z-index: 999;
  @media (max-width: 768px) {
    position: relative;
  }
`;
