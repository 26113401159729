import React from "react";
import SupportWidget from "../../../component/SupportWidget";
import googleStoreImg from "../../../image/google.png";
import Navbar from "../../../component/Navbar";
import { ButtonContainer, HeroText, Containers } from "../CobankingApp/styles";
import Fade from "react-reveal/Fade";
import Footer from "../../../component/Footer";
import img from "../../../image/agimg.png";
import styled from "styled-components";
import FaqAccordion from "../AgentBank/FaqAccordion";
import { data } from "./accordionData";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import { Button } from "../../../component/Button";
import { useNavigate } from "react-router-dom";
const Cobank = () => {
  const navigate = useNavigate();
  // useEffect(() => {
  //   document.title = "Management App for Thrift Collection - Download Now";
  // }, []);
  return (
    <>
      <Navbar img="/Logo.png" />
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Riby Cooperative Agent -We provide agency banking solution for all financial services
          transaction{" "}
        </title>
        <description></description>
        <meta
          name="description"
          content="Riby Cobanking is winning the race to becoming Nigeria's best cooperative software
          solution. You can grow your cooperative members, carryout thrift collection activities, as
          well as managing loan disbursement and repayment"
        />
      </Helmet>
      <Containers>
        <Hero>
          <Fade left>
            <HeroText>
              <h2 style={{ color: "#0f0738" }}>
                A Cooperative Collection and managment app for thrift and cooperative collection{" "}
              </h2>

              <p>
                Riby cooperative agent app allows for cooperatives, thrift collectors, trade unions,
                associations and groups automate and manage their entire process of member savings
                collections, contributions and loan booking/repayment from their members and
                customers.
              </p>
              <ButtonContainer>
                <a href="#">
                  <img src={googleStoreImg} alt="" />
                </a>
                {/* <a href="#">
                  <img src={playStoreImg} alt="" />
                </a> */}
              </ButtonContainer>
            </HeroText>
          </Fade>
          <Fade right>
            <ImageContainer>
              <HeroImg src={img} alt="hero img for coagent" />
            </ImageContainer>
          </Fade>
        </Hero>
        <Container>
          <Content>
            <h2>Has your cooperative lost money because your field agents ran away?</h2>
            <p>
              Riby Cooperative Agent Network allow cooperatives to manage thrift collection
              smoothly, thereby increasing cooperative membership and contributions.
            </p>
            <p>This application is an icing on the cake if you already signed up on Cobanking.</p>
            <Button title="Get Started" onClick={() => navigate("/registercooperative")} />
          </Content>
        </Container>
        <Faq>
          <h3>Frequently Asked Questions</h3>
          {data.map((data, i) => (
            <FaqAccordion key={i} data={data} />
          ))}
        </Faq>
      </Containers>
      <SupportWidget />
      <Footer />
    </>
  );
};

export default Cobank;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem 5px;
  flex-direction: column;
  color: #0f0738;
  h2 {
    color: #0f0738;
    text-align: center;
  }
  p {
    font-size: 1.25rem;
    text-align: center;
    margin: 0;
  }
`;

const Faq = styled.div`
  width: 100%;
  // min-height: 70vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 570px;
  height: 570px;
  border-radius: 50% 50% 50% 50%;
  margin-left: 30px;
  background: #83909e;
  overflow: hidden;
  @media (max-width: 768px) {
    margin-left: 0;
    width: 400px;
    height: 400px;
    // width: 100%;
  }
  @media (max-width: 400px) {
    margin-left: 0;
    width: 300px;
    height: 300px;
  }
`;
export const Underline = styled.a`
  color: #0d223d;

  &:hover {
    text-decoration: underline;
  }
`;

export const HeroImg = styled.img`
  width: 400px;
  margin-left: 50px;
  position: absolute;
  top: 120px;
  left: 30px;
  // clip: rect(25px, 100px, 100px, 25px);
  @media (max-width: 768px) {
    margin-left: 0;
    top: 80px;
    left: 50px;
    width: 300px;
  }
  @media (max-width: 450px) {
    margin-left: 0;
    width: 250px;
    top: 30px;
    left: 30px;
  }
  @media (width: 414px) {
    margin-left: 0;
    width: 300px;
    top: 70px;
    left: 60px;
  }
`;

export const Hero = styled.div`
  width: 100%;
  height: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap-reverse;

  @media (max-width: 768px) {
    justify-content: space-around;
    margin-top: 0px;
    min-height: 100vh;
  }
`;
