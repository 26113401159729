import React, { useEffect } from "react";
import Navbar from "../../component/Navbar";
import Footer from "../../component/Footer";
import Map from "../../component/map/maps";
import { BiPhone } from "react-icons/bi";
import { BsWhatsapp } from "react-icons/bs";
import "./Contact.css";

const location = {
  address: "Block G House 4, Moore Road, Yaba, Lagos.",
  lat: 6.51381,
  lng: 3.3785,
};
const Contact = () => {
  useEffect(() => {
    document.title = "Contact Riby - What Business need do you wan to solve, talk to us";
  }, []);

  return (
    <>
      <Navbar img="/Logo.png" />
      <div className="cnt-container">
        <div className="cnt-wrapper">
          <div className="cnt1">
            <h1>Contact Us</h1>
            <p>We are always available for you.</p>
          </div>
        </div>

        <div className="contact-form">
          <div className="contactInfo">
            <h3>Please tell us how we can meet your business needs</h3>
            <h4> Fill the form and we'll get back to you.</h4>
            <h3>Get in Touch</h3>
            <p>Block G, House 4, Moore Road, Yaba, Lagos.</p>
            <hr />
            {/* <a style={{ color: "#A12029", fontSize: "20px" }} href="mailto:info@riby.ng">
              info@riby.ng{" "}
            </a> */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <BsWhatsapp />
              <a style={{ color: "#A12029", marginLeft: "3px" }} href="https://wa.link/pva8dc">
                {" "}
                +234 809 222 2109
              </a>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <BiPhone />
              <a style={{ color: "#A12029", marginLeft: "3px" }} href="tel:012914247">
                01 291 4247
              </a>
            </div>
          </div>
          <form className="formm">
            <input placeholder="Full name" />

            <input placeholder="Email Address" />
            <input placeholder="Message" />
            <a href="mailto:info@riby.ng">
              <div className="btn-submit">Send</div>
            </a>
          </form>
        </div>
      </div>

      <Map />
      <Footer />
    </>
  );
};

export default Contact;
