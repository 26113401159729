import { BsDot } from "react-icons/bs";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import pIcon1 from "../../image/pIcon1.png";
import pIcon2 from "../../image/pIcon2.png";
import pIcon3 from "../../image/pIcon3.png";

import React from "react";

const Purpose = () => {
  const data = [
    {
      img: pIcon1,
      textHeader: "Save Consistently",
      description:
        " Our digital banking platform makes it easy and convenient for individuals and group members to make their saving and contribution at their desired frequency.",
    },
    {
      img: pIcon2,
      textHeader: "Borrow Easily",
      description:
        "Easily borrow and manage loans for members of your Groups or Cooperatives. Get connected to Riby financial service partners for more funding opportunities for your cooperative and business.",
    },
    {
      img: pIcon3,
      textHeader: "Invest Together",
      description:
        "Manage your internal pool of funds easily and conveniently using our digital platform and get access to several investment opportunities offered by Riby partners all managed securely.",
    },
  ];

  return (
    <Container>
      <Wrapper>
        <Fade top>
          <HeaderContainer>
            <HeaderTxt>
              Save Consistently <BsDot /> Borrow Easily <BsDot /> Invest Together
            </HeaderTxt>
            <HeaderP>
              In partnership with banks, relevant corporates, payment & card processing companies,
              the Riby platforms helps micro/small businesses and middle to low Income salary
              earners within these groups/clusters to create and manage bank or mobile money
              accounts and gain access to financial services
            </HeaderP>
          </HeaderContainer>
        </Fade>
        <Zoom bottom>
          <FlexContainer>
            {data.map((data, index) => (
              <Card key={index}>
                <Img src={data.img} />
                <Content>
                  <h6>{data.textHeader}</h6>
                  <p>{data.description}</p>
                </Content>
              </Card>
            ))}
          </FlexContainer>
        </Zoom>
      </Wrapper>
    </Container>
  );
};
const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1.5rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const HeaderTxt = styled.h4`
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  color: #0f0738;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;
const Img = styled.img``;
const Content = styled.div`
  padding: 3px 6px;
  h6 {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 600;
    color: #0f0738;
    font-size: 1.2rem;
  }
  p {
    margin: 0;
    font-family: "Outfit";
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 16px;
    color: #0f0a38;
  }
`;
const Card = styled.div`
  width: 350px;
  height: 100%;
  min-height: 200px;
  padding: 7px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #6b778c;
  text-align: center;
  border-radius: 7px;
  margin: 10px;
  transition: all 350ms;
  &:hover {
    cursor: pointer;
    border: 1px solid #d80c1b;
    box-shadow: 0px 4px 4px rgba(15, 7, 56, 0.16);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
const HeaderP = styled.p`
  position: relative;
  font-weight: 400;
  font-size: 18px;
  width: 80%;
  margin: 0;
  color: #0f0a38;
  text-align: center;

  font-family: "Outfit";
  @media (max-width: 768px) {
    width: 100%;
    font-size: 1.1em;
    text-align: center;
    padding: 15px;
  }
  span {
    color: #d80c1b;
    // border-bottom: 1px solid #d80c1b;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #0f0738;
  background: ##f8feff;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 75vh;
  background: ##f8feff;
`;

export default Purpose;
