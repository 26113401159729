export const data = [
  {
    question: "How do I become an agent",
    number: "1",
    answer:
      "Visit agency.riby.ng and sign up. Please note that signing up does not automatically make you an agent, you will be contacted, only if you meet all the requirements",
  },
  {
    question: "What do I need to become an agent",
    number: "2",
    otherAnswer: [
      "Agent must have a BVN",
      "Agent must be literate",
      "Agent must have an Android phone",
      "Agent must have active phone number",
      " Agent must have active bank account",
    ],
    // "Visit agent.riby.me and sign up. Please note that signing up does not automatically make you an agent, you would be contacted, only if you meet all the requirements.",
  },
  {
    number: "3",
    question: "What is the app used for?",
    answer:
      "Riby Agent app is specially designed with unique features that integrates with different payment channels on a POS to perform financial operations such as, cash withdrawals (Cash Out), cash deposits (Cash In), bill payments and other related financial services",
  },
  {
    number: "4",
    question: "How do I download the Riby Agent app?",
    answer:
      "Please visit Playstore on your device, search for Riby Agent App, download and install the app",
  },
  {
    number: "5",
    question: "How do I make money as an agent",
    answer:
      "There are several revenue channels for agents to make money through the app and being a Riby agent comes with a lot of perks. All these will be discussed with you once you become a registered agent.",
  },
  {
    number: "6",
    question: "What do I do if i mistakenly send money to a wrong account?",
    answer:
      "We suggest you contact the person you wrongly credited to recover your money by reporting the error to your bank. Riby is not responsible for any incorrect transaction carried out by agents, it is advisable to always cross check your transactions before initiating it.",
  },
  {
    number: "7",
    question: " What should I do if I encounter any issues with the app or forget my password?",
    answer:
      "To recover yo​ur password or encounter issues, please contact your WhatsApp admin first. If the issue cannot be resolved by him/her, then call the support line: +234 704 716 6345 or send a mail to agentsupport@riby.ng",
  },
  {
    number: "8",
    question: "What do I do if I encounter any issue with the platform?",
    answer:
      "Please contact your admin first. If the issue cannot be resolved by him/her, call the support line: 0809 2222 109 or send a mail to support@riby.ng",
  },
];
