import React from "react";
import styled from "styled-components";
import Navbar from "../../component/Navbar";

import Footer from "../../component/Footer";
import SupportWidget from "../../component/SupportWidget";
// import { ProductCard } from "../Product/CobankingApp/ProductCard";
import "./About.css";
import AboutpageSection1 from "./Aboutpage-section1/Aboutpage-section1";
// import OurPrep2 from "./OurPrep2/OurPrep2";
import aboutImg1 from "../../image/about1.png";
import aboutImg2 from "../../image/about2.png";
import aboutImg3 from "../../image/about3.png";
import aboutImg4 from "../../image/about4.png";
import aboutImg5 from "../../image/aboutNewImg.jpeg";
import Zoom from "react-reveal/Zoom";

import Fade from "react-reveal/Fade";
import { Members } from "../../helper/careerdata";
import { Helmet } from "react-helmet";
const { useEffect } = React;

const About = () => {
  // useEffect(() => {
  //   document.title = "About Us - Riby";
  // }, []);

  return (
    <>
      <Navbar img={"/Logo.png"} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          About Us - Riby assist individuals in economy clusters, SMEs, cooperatives, and trade
          groups to access financial services
        </title>
        <meta
          name="description"
          content="Riby Cobanking is winning the race to becoming Nigeria's best cooperative software
          solution. You can grow your cooperative members, carryout thrift collection activities, as
          well as managing loan disbursement and repayment"
        />
      </Helmet>
      <div className="abt-container">
        <div className="hero-section">
          <div className="abtHero">
            <Fade top>
              <h1>About Us</h1>
            </Fade>
          </div>
        </div>
        <AboutpageSection1
          fd
          img={aboutImg1}
          heading="Who We Are"
          text1="Riby is a digital and financial services company and is one of Nigeria’s foremost digital platforms providers for cooperatives,informal savings groups (ajo, esusu, thrift collections), micro & small-scale enterprises, and trade groups."
          text2="We assist individuals in economy clusters, SMEs, cooperatives, and trade groups to access financial services through our digital platforms."
        />
        <Fade bottom>
          <div className="Aboutpage-section2">
            <div className="Our-promises">
              <h1>Our Premise (#BetterTogether)</h1>
              <p>
                In Nigeria there are over 40 million individuals that save and borrow money outside
                of formal financial institutions. This often takes the form of common saving
                vehicles such as Savings Groups, collectors and community/trade associations. These
                cooperatives often do not wholly participate in the banking system meaning that
                their access to finance is limited and the management of these vehicles is mostly
                manual.
              </p>
              <span style={{ fontStyle: "italic", fontSize: "1rem" }}>
                " Digital Access: The Future of Financial Inclusion in Africa, IFC, Mastercard
                Foundation, 2018"
              </span>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className="single-image">
            <img src={aboutImg2} alt="" />
          </div>
        </Fade>

        <Zoom bottom>
          <div className="our-vision">
            <h1>OurVision</h1>
            <p>Is to enable access to collective growth</p>
          </div>
        </Zoom>
        <Zoom bottom>
          <div className="mission">
            <h1>Our Mission</h1>
            <p>
              Is to Build affordable and accessible digital platforms to promote collective growth.
            </p>
          </div>
        </Zoom>
        <AboutpageSection1
          fd
          img={aboutImg3}
          heading="Our Proposition"
          text1="Riby responded to this need and market gap by building a group-based digital platform focused on enabling access to collective growth for this economic groups and subsequently for individuals."
          text2="In addition to the platform services the company provides for the efficient self- running of the groups for savings/ contributions, loans, and investments, Riby provides access to financial services for individuals in Cooperatives, Informal Savings Groups, Micro & Small- Scale Enterprises, Trade Groups as well as economic clusters"
        />

        <AboutpageSection1
          img={aboutImg5}
          text2="Collectively, Riby have supported over 4 million Nigerians (both formal and informal) across the country in accessing financial services. Over the course of our journey, we’ve partnered with several organizations, including: Bank of Industry, MTN, Providus Bank, Sterling Bank, Union Bank and many more."
        />
        <StaffContainer>
          <Fade top>
            <h1 style={{ color: "#0f0738", marginTop: "2rem" }}>Our Team</h1>
          </Fade>

          <FlexContainer>
            {Members.map((Members, index) => (
              <>
                <Fade bottom>
                  <WholeCard>
                    <MemberCard key={index}>
                      <div className="shadow">
                        <h5>
                          <a
                            href={Members?.link}
                            style={{ color: "#EB4967" }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            View Profile
                          </a>
                        </h5>
                      </div>
                      <img src={Members.image} alt="" />
                    </MemberCard>
                    <MemberContent>
                      <p>{Members.memberName}</p>
                      <span>{Members.position}</span>
                    </MemberContent>
                  </WholeCard>
                </Fade>
              </>
            ))}
          </FlexContainer>
        </StaffContainer>
      </div>
      <SupportWidget />
      <Footer />
    </>
  );
};

export const Shadow = styled.div`
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  // display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 300px;
  z-index: 1;
  display: none;
  transition: all 350ms;
  &:hover {
    cursor: pointer;
    display: flex;
  }

  h5 {
    color: #fff;
  }
`;

const StaffContainer = styled.div`
  width: 100%;
  min-height: 70vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const WholeCard = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const FlexContainer = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const MemberCard = styled.div`
  position: relative;
  width: 250px;
  height: 300px;
  padding: 7px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  img {
    width: 300px;
    height: 300px;
    border-radius: 10px;
    // object-fit: cover;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
  .shadow {
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 300px;
    z-index: 1;
    opacity: 0;
    transition: all 350ms;
    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    h5 {
      color: #fff;
    }
  }
`;

const MemberContent = styled.div`
  // padding: 3px 6px;
  margin-bottom: 20px;
  margin-left: 20px;
  color: #0f0738;
  font-family: "Outfit";

  p {
    margin: 0;
    padding: 0;
    font-family: "Outfit";
    font-style: normal;
    font-weight: 00;
    font-size: 0.9rem;
    // line-height: 16px;
    color: #0f0738;
  }
  span {
    span: 0;
    color: #8b8aa1;
  }
`;

export default About;
