import "./Career-Section4.css";
import Flash from "react-reveal/Flash";

const Section4 = () => {
  return (
    <>
      <Flash>
        <div className="image3-container">
          <iframe
            // width="100%"
            // height="100%"
            className="iframes"
            src="https://www.youtube.com/embed/8sC2Wn5Jp3Q"
            title="hr video"
            frameBorder={0}
          ></iframe>
        </div>
      </Flash>
    </>
  );
};
// https://youtu.be/8sC2Wn5Jp3Q
export default Section4;
