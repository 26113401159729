import styled, { keyframes } from "styled-components";
import React, { useState, useEffect } from "react";
import finImg from "../../image/bizImg4.webp";
import gallery1 from "../../image/galleryimg1.jpeg";
import gallery2 from "../../image/galleryImg2.jpeg";
import gallery3 from "../../image/galleryImg3.jpeg";
import gallery4 from "../../image/galleryImg4.jpeg";
import gallery6 from "../../image/galleryImg6.jpeg";
import bizImg from "../../image/bizImg2.png";
import riby1 from "../../image/riby1.jpeg";
import riby2 from "../../image/riby2.jpeg";
import riby4 from "../../image/riby4.jpeg";
import riby5 from "../../image/riby5.jpeg";
import riby6 from "../../image/riby6.jpeg";
import { Link } from "react-router-dom";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
const BusinessComponent = () => {
  const bizData = [
    {
      id: 1,
      img: bizImg,
    },
    {
      id: 2,
      img: finImg,
    },
    {
      id: 3,
      img: gallery1,
    },
    {
      id: 4,
      img: gallery2,
    },
    // {
    //   id: 5,
    //   img: gallery4,
    // },
    {
      id: 6,
      img: gallery3,
    },
    {
      id: 7,
      img: gallery6,
    },
    {
      id: 8,
      img: riby1,
    },
    {
      id: 8,
      img: riby2,
    },

    {
      id: 10,
      img: riby4,
    },
    {
      id: 11,
      img: riby5,
    },
    {
      id: 12,
      img: riby6,
    },
  ];
  const [index, setIndex] = useState(0);

  const { id, img } = bizData[index];

  const checkNumber = (number) => {
    if (number > bizData.length - 1) {
      return 0;
    }
    if (number < 0) {
      return bizData.length - 1;
    }
    return number;
  };

  const nextSlide = () => {
    setIndex((index) => {
      let newIndex = index + 1;
      return checkNumber(newIndex);
    });
  };
  const prevSlide = () => {
    setIndex((index) => {
      let newIndex = index - 1;
      return checkNumber(newIndex);
    });
  };

  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () => setIndex((prevIndex) => (prevIndex === bizData.length - 1 ? 0 : prevIndex + 1)),
      2000
    );

    return () => {
      resetTimeout();
    };
  }, [index]);
  return (
    <Container>
      <BtnContainer>
        <button onClick={prevSlide}>
          <AiOutlineLeft />
        </button>
        <button onClick={nextSlide}>
          <AiOutlineRight />
        </button>
      </BtnContainer>
      <Carousel bg={img} key={id}></Carousel>

      <Container2>
        <img src={bizImg} alt="" />
        <img src={finImg} alt="" />
        <img src={bizImg} alt="" />
        <img src={finImg} alt="" />
        <img src={gallery1} alt="" />
        <img src={gallery1} alt="" />
        <img src={gallery2} alt="" />
        <img src={gallery3} alt="" />
        {/* <img src={gallery4} alt="" /> */}
        <img src={gallery6} alt="" />
        <img src={riby1} alt="" />
        <img src={riby2} alt="" />
        <img src={riby4} alt="" />
        <img src={riby5} alt="" />
        <img src={riby6} alt="" />
        <img src={bizImg} alt="" />
        <img src={finImg} alt="" />
        <img src={gallery1} alt="" />
        <img src={gallery2} alt="" />
        <img src={gallery3} alt="" />
        {/* <img src={gallery4} alt="" /> */}
      </Container2>
    </Container>
  );
};

export default BusinessComponent;

const BtnContainer = styled.div`
  display:none;
@media(max-width:768px){
  display: none;
  justify-content: right;
  align-items: center;
  float: right;
  position: relative;
  right: 0px;
  button{
    outline:none;
    borderL1px solid #0d233d;
    color:black;
    background:transparent;
    margin:10px;
  }
}
  // width: 100%;
`;
const Carousel = styled.div`
  display: none;

  @media (max-width: 1200px) {
    display: flex;
    width: 100%;
    position: relative;

    height: 70vh;
    background: url(${({ bg }) => bg});
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.9;
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
  }
  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    position: relative;

    height: 50vh;
    background: url(${({ bg }) => bg});
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.9;
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
  }
`;

const bannermove = keyframes`
   100% {
        transform: translateX(-26.6666%);
    }
    0%{
      transform: translateX(36.6666%);
    }

`;

const Container2 = styled.div`
  max-width: 6000px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${bannermove} 17s linear infinite;
  transform: translate3d(0, 0, 0);
  img {
    display: inline-block;
    margin-left: 1rem;
    margin-right: 1rem;
    transition: opacity 0.5s ease-out;
    border-radius: 10px;
    // height: 400px;
    width: 700px;

    &:hover {
      opacity: 0.7;
    }
  }
  @media (max-width: 1200px) {
    // width: 3000px;
    display: none;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    display: none;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`;
