import { useState, useEffect } from "react";
import "./Career-Section2.css";
import styled, { keyframes } from "styled-components";
import { FaTimes } from "react-icons/fa";
import Fade from "react-reveal/Fade";
import { useFetchJob } from "../../../hooks/usefetchJobs";
import { useFetchSingle } from "../../../hooks/useFetchSingle";
import { useApply } from "../../../hooks/useApply";
// import { GET_ALL_JOBS } from "../../../utils/queries/allQueries";
// import { GET_ALL_JOBS, GET_JOB } from "../../../utils/queries/allQueries";
// import { getJob } from "../../../utils/queries/allQueries";
// import { useLazyQuery, useQuery } from "@apollo/client";
import Modal from "../../../component/Modal";
import img1 from "../../../image/careerImg1.png";
import img2 from "../../../image/careerimg2.png";
import img3 from "../../../image/careerimg3.png";
import img4 from "../../../image/careerimg4.png";
import { useUpload } from "../../../hooks/useUpload";

const Section2 = () => {
  const [showModal, setShowModal] = useState(false);
  const [showJob, setShowJob] = useState(false);
  const [btnErr, setBtnErr] = useState(false);
  const { loading, error, data, fetchJob } = useFetchJob();
  const { singleData, fetchSingle, pending } = useFetchSingle();
  const { cv, progressBar, uploadImg, loader } = useUpload();
  const { isLoading, applyJob, success, err } = useApply();

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  useEffect(() => {
    fetchJob();
  }, []);

  console.log(progressBar, "msm");

  const handleAppyJob = (role) => {
    if (email.length && phone.length && name.length) {
      applyJob(name, email, phone, role, cv);
      setBtnErr(false);
      setName("");
      setEmail("");
      setPhone("");
    } else {
      setBtnErr(true);
    }
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setShowModal(false);
      }, 1500);
    }
  }, [success]);

  const getSingleJob = (id) => {
    fetchSingle(id);
    setShowModal(true);
  };

  const handleShowJobs = () => {
    setShowJob(!showJob);
  };

  return (
    <>
      <section className="career-section2">
        <Fade left>
          <div className="text-content">
            <h1>Come Work With Us</h1>
            <p>We're looking for great minds to join our #BETTERTOGETHER growing team.</p>
            <p>
              At Riby, we are always looking for talented individuals who aligns with our mission to
              enabling access to collective growth for 63 million banked and unbanked Nigerians.
            </p>
            <button className="see-all-openings" onClick={handleShowJobs}>
              {" "}
              See All Openings
            </button>
          </div>
        </Fade>
        <Fade right>
          <div className="section2-images">
            <Img mt="10px" src={img1} />
            <Img mt="21px" src={img2} />
            <Img mt="4px" src={img3} />
            <Img mt="18px" src={img4} />
          </div>
        </Fade>
      </section>
      <>
        {showJob && (
          <Container>
            <h3>See available position</h3>
            <Wrapper>
              {data?.length === 0 && <p>No job is available currently</p>}
              {!loading &&
                !error &&
                data?.map(
                  (data) =>
                    data?.data?.status && (
                      <JobCard key={data.id} onClick={() => getSingleJob(data?.id)}>
                        {data?.data?.role}
                      </JobCard>
                    )
                )}
            </Wrapper>
          </Container>
        )}
        {showModal && (
          <Modal hideShowModal={() => setShowModal(false)}>
            {pending ? (
              <p>loading...</p>
            ) : (
              <div className="job-modal">
                <h3>{singleData?.role}</h3>
                {/* <p>Level: Entry-Level</p>
                 */}
                <small>{singleData?.years}years</small>
                <div>
                  <p>{singleData?.description}</p>
                  <p>Candidate must possess</p>
                  <ul>
                    {singleData?.skills?.map((data, index) => (
                      <Li key={index}>{data.skill}</Li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h6 style={{ textAlign: "left" }}>Apply now</h6>
                  <section>
                    <label htmlFor="Name">Name *</label>
                    <Input
                      type="text"
                      value={name}
                      placeholder="Name"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </section>
                  <section>
                    <label htmlFor="email address">Email Address *</label>
                    <Input
                      type="email"
                      value={email}
                      placeholder="Enter a valid Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </section>

                  <section>
                    <label htmlFor="Mobile">Mobile Phone number *</label>
                    <Input
                      type="text"
                      value={phone}
                      placeholder="Input your phone number"
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </section>
                  <section>
                    <label htmlFor="resume">Please submit a resume</label>
                    <Input type="file" onChange={uploadImg} />

                    {progressBar < 1 ? (
                      <small style={{ color: "red" }}>
                        {Math.floor(progressBar)}% of cv upload
                      </small>
                    ) : (
                      <small style={{ color: "green" }}>
                        {Math.floor(progressBar)}% of cv upload
                      </small>
                    )}

                    {/* {progressBar >= 2 &&
                      `Your cv has ${Math.floor(progressBar)}% successfully updated`} */}
                  </section>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
                  <Button bd color onClick={() => setShowModal(false)}>
                    Cancel
                  </Button>
                  {isLoading ? (
                    <Button bg disabled>
                      Loading
                    </Button>
                  ) : (
                    <Button
                      bg
                      disabled={progressBar !== 100}
                      onClick={() => handleAppyJob(singleData?.role)}
                    >
                      Submit
                    </Button>
                  )}
                </div>
                {success ? (
                  <p style={{ color: "green", fontSize: "14px" }}>Successfully Applied</p>
                ) : (
                  <p>{err}</p>
                )}
                {btnErr ? (
                  <p style={{ color: "red", fontSize: "12px" }}> Please input all fields</p>
                ) : (
                  ""
                )}
                <Times onClick={() => setShowModal(false)}>
                  <FaTimes />
                </Times>
              </div>
            )}
          </Modal>
        )}
      </>
    </>
  );
};

export default Section2;

const Img = styled.img`
  object-fit: cover;
  width: 230px;
  height: 230px;
  border-radius: 7px;
  background: transparent;
  margin: 7px;
  margin-top: ${({ mt }) => mt};

  @media (max-width: 500px) {
    width: 150px;
    height: 150px;
  }
`;

const Button = styled.button`
  width: 150px;
  height: 40px;
  background: ${({ bg }) => (bg ? "#0f0738" : "transparent")};
  border: ${({ bd }) => (bd ? " 1px solid #0f0738" : "")};
  color: ${({ color }) => (color ? "#0f0738" : "white")};
  margin-right: 0.5rem;
  border-radius: 7px;
`;
const Times = styled.button`
  position: absolute;
  top: 35px;
  right: 10px;
  font-size: 20px;
  background: transparent;
  outline: none;
  border: 0;
`;
const Input = styled.input`
  width: 100%;
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 15px;
  outline: none;
`;
const Li = styled.li`
  margin: 0;
  padding: 0;
`;

const JobCard = styled.div`
  // width: 200px;
  // height: 50px;
  padding: 10px;
  background: whitesmoke;
  color: #0f0738;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  justify-content: left;
  align-items: center;
  margin: 10px;
`;

const Wrapper = styled.div`
  width: 70%;
  min-height: 20vh;

  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  transition: all 350ms;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const scroll = keyframes`

 0% {
    opacity:0;
  }
  70%{
    transform:translatY(0)
  }
  100%{
    opacity:1;
    transform:translateY(4px);
    display:flex;
  }
`;
const Container = styled.section`
  h3 {
    color: #0f0738;
  }
  width: 100%;
  min-height: 20vh;
  height: 100%;
  color: #0f0738;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  transition: all 350ms;
  margintop: -40px;
  // display: none;
  animation: ${scroll} 0.5s ease forwards;
`;
