import styled from "styled-components";

export const Button = ({ onClick, title }) => {
  return <ButtonComponent onClick={onClick}>{title}</ButtonComponent>;
};

const ButtonComponent = styled.div`
  width: 200px;
  height: 50px;
  // background: #d80c1b;
  background: linear-gradient(285deg, #f00b51, #e20082);
  border-radius: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
